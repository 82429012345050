/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Drawer,
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Grid,
    TextField,
    Button,
    IconButton,
    Tooltip,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Field, Form, FormSpy, AnyObject, FieldInputProps } from 'react-final-form';
import { mainTheme } from '../../Theme';
import { FormApi, ValidationErrors } from 'final-form';
import { OrganisationSelector, PartyQueryParams, retrievePartyData, PartyData } from '../selectors/OrganisationSelector';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { AddressSelector, PlaceType, Address } from '../selectors/AddressSelector';
import BusinessIcon from '@material-ui/icons/Business';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import LocalPostOfficeIcon from '@material-ui/icons/LocalPostOffice';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { showNotification } from '../../App';
import { CreateContactMutation, CreateClientContactMutation, CreateClientAndContactMutation } from '../EnquirySummaryRepository';
import { client } from '../..';
import { Actions, EnquiryDetail } from '../models/Enquiry';
import SubjectIcon from '@material-ui/icons/Subject';
// import { client } from '../..';
// import { CreateClientAndContactMutation, CreateClientContactMutation, CreateContactMutation } from '../EnquirySummaryRepository';
// import { keys } from 'ts-transformer-keys';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { CurrencyInput } from '../../components/CurrencyInput';
import { CurrencySymbolData, retrieveCurrencySymbolData } from '../../ClientAndContactDetails/referrals/ReferralsDetailsRepository';
// import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
// import { GenderSelector } from '../selectors/GenderSelector';
// import { MatterTypeSelector } from '../selectors/MatterTypeSelector';
import { ClientAndContactSelector } from '../../components/ClientAndContactSelector';
// import { MatterCategorySelector } from '../selectors/MatterCategorySelector';
import { MatterTypeStateSelector } from '../selectors/MatterTypeStateSelector';
import { RecurringMatterItem, RecurringMatterSelector } from '../selectors/RecurringMatterSelector';
import { StaffSelector } from '../selectors/StaffSelector';
import { KeyboardDatePicker } from '@material-ui/pickers/DatePicker/DatePicker';
import { GenderSelector } from '../selectors/GenderSelector';
import moment from 'moment';
import { POBoxTypeSelector } from '../../lookupConfiguration/selectors/POBoxTypeSelector';
// import { HistoryFile } from '../common/HistoryFile';
import { TimezoneOption } from '../../lookupConfiguration/selectors/TimezoneSelector';
import { LeadDetailsHistoryData, LeadDetailsHistoryQueryParams, retrieveLeadDetailsHistoryData, TaskSummaryList } from '../../leadsDetail/LeadsDetailRepository';
import { HistoryFile } from '../common/HistoryFile';
import { TitleAutocompleteSelector } from '../selectors/TitleAutocompleteSelector';
import { ReportingGroupSelector } from '../selectors/ReportingGroupSelector';
import { MatterTreeTypeSelector } from '../selectors/MatterTreeTypeSelector';
import { TreeSelectNode } from '../../components/TreeSelect';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { formatDateString } from '../../helpers/DateHelper';
import { LocalStorageEnum } from '../../enums/LocalStorageEnum';
import { CustomFieldByHierarchy } from '../../enquiryCustomFields/CustomFieldRepositores';
// import { RvLoader } from '../../components/Loader';
// import { WorkObtainedSelector } from '../selectors/WorkObtainedSelector';
// import { HistoryActivityWidth } from '../common/HistoryActivity';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > .MuiDrawer-paper': {
                maxWidth: '700px',
            },
        },
        formRoot: {
            width: '100%',
            padding: '20px 15px',
        },
        expandablePanelDetails: {
            paddingBottom: '15px',
            '& .downshift-wrapper': {
                padding: '0px !important',
                maxWidth: 'auto !important',
            },
        },
        AccordionSummary: {
            '& .MuiAccordionSummary-content': {
                justifyContent: 'space-between',
                alignItems: 'center',
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            // fontWeight: theme.typography.fontWeightRegular,
            color: mainTheme.TemplateColor.Primary,
            display: 'flex',
            alignItems: 'flex-end',
        },
        headingIcon: {
            marginRight: '10px',
        },
        infoContainer: {
            display: 'flex',
            margin: '0 -10px',
            paddingLeft: '10px',
            '& .col': {
                padding: '0 10px',
                display: 'flex',
                alignItems: 'center',
                '& .label': {
                    marginRight: '10px',
                },
                '& .files': {
                    fontWeight: 600,
                },
                '& .ellipsis': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '250px',
                    textAlign: 'right',
                },
                '& .value': {
                    color: mainTheme.BrandColors.TemplatePrimary,
                    textAlign: 'right',
                    width: '400px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                },
            },
        },
        textField: {
            width: '100%',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            margin: theme.spacing(1),
        },
        hidden: {
            display: 'none',
        },
        margin: {
            margin: theme.spacing(1),
        },
        organisationFieldWrapepr: {
            display: 'flex',
            alignItems: 'center',
            '& .field': {
                flex: 1,
            },
            '& .switcher': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
        },
        fieldAndCheckbox: {
            display: 'flex',
            alignItems: 'center',
        },
        customFieldGrid: {
            // paddingTop: '0px !important',
        },
        formControlLabel: {
            // marginLeft: 0,
            // marginRight: 0,
        },
        loaderWrapper: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minWidth: '700px'
        }
    }),
);

interface CreateClientDialogProps {
    anchor?: 'left' | 'top' | 'right' | 'bottom';

    guid?: string;
    homeCountry?: string;
    sourceSystem?: string;
    onDialogClose?: () => void;

    title: string | null;
    firstName: string;
    lastName: string;
    organisation: string;
    email: string;
    mobile: string;
    phone: string;
    sourceNotes: string;
    source: string | null;
    enquiryActions?: Actions;
    enquiryDetail: EnquiryDetail;
    poBoxType: string | null;
    timezone: TimezoneOption[];
    customFieldByHierarchy?: CustomFieldByHierarchy[];
}

interface Organisation {
    organisation: IAutoCompleteItem | null;
    organisationText: string;
    orgAddress: string;
    orgBuildingName: string;
    orgUnitLevel: string;
    orgNumber: string;
    orgStreet1: string;
    orgStreet2: string;
    orgCitySuburb: string;
    orgState: string;
    orgPostalCode: string;
    orgCountry: string;

    orgPoBoxType: string | null;
    orgPostalPOBox: string;
    orgPostalCitySuburb: string;
    orgPostalState: string;
    orgPostalPostalCode: string;
    orgPostalCountry: string;

    isOrganisationFromDataSource: boolean | null;
}

interface Person {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    phone: string;
    generalNotes: string;
    address: string;
    buildingName: string;
    unitLevel: string;
    number: string;
    street1: string;
    street2: string;
    citySuburb: string;
    state: string;
    postalCode: string;
    country: string;

    postalPoBoxType: string | null;
    postalPOBox: string;
    postalCitySuburb: string;
    postalState: string;
    postalPostalCode: string;
    postalCountry: string;

    birthDate: Date | null;
    gender: string | null;
    isMarketingConsent: boolean;

    isNameFromDataSource: boolean | null;
}

interface Person2 {

    secondPersonTitle: string;
    secondPersonFirstName: string;
    secondPersonLastName: string;
    secondPersonEmail: string;
    secondPersonMobile: string;
    secondPersonPhone: string;
    secondPersonGeneralNotes: string;
    secondPersonAddress: string;
    secondPersonBuildingName: string;
    secondPersonUnitLevel: string;
    secondPersonNumber: string;
    secondPersonStreet1: string;
    secondPersonStreet2: string;
    secondPersonCitySuburb: string;
    secondPersonState: string;
    secondPersonPostalCode: string;
    secondPersonCountry: string;

    secondPersonPostalPoBoxType: string | null;
    secondPersonPostalPOBox: string;
    secondPersonPostalCitySuburb: string;
    secondPersonPostalState: string;
    secondPersonPostalPostalCode: string;
    secondPersonPostalCountry: string;

    secondPersonBirthDate: Date | null;
    secondPersonGender: string | null;
    secondPersonIsMarketingConsent: boolean;

    isOtherSideFromDataSource: boolean | null;
    isSecondClientFromDataSource: boolean | null;
}

interface CreateMatterProps {
    useRecurringMatter: boolean;
    recurringMatter: IAutoCompleteItem | null;
    matterCategory: IAutoCompleteItem | null;
    matterTypeState: IAutoCompleteItem | null;
    matterType: IAutoCompleteItem | null;
    personResponsible: IAutoCompleteItem | null;
    personActing: IAutoCompleteItem | null;
    personAssisting: IAutoCompleteItem | null;
    credit: IAutoCompleteItem | null;
    matterTitle: string | null;
    referrer: IAutoCompleteItem | null;
    costAgreementRequired: boolean;
    estimatedFees: number | null;
    estimatedDisbursements: number | null;
    isEstimatedFeesIncludesTax: boolean;
    isEstimatedDisbursementsIncludesTax: boolean;
    matterTypeTree: TreeSelectNode | null;
}

interface CreateClientForm extends Organisation, Person, CreateMatterProps, Person2 {
    workObtained: IAutoCompleteItem | null;
    reportingGroup: IAutoCompleteItem | null;
}

interface CreateClientState {
    showAlert: boolean;
    isForceClosed: boolean;
    showConfirmed: boolean;
    isFormChanged: boolean;
    isSwitched: boolean;
    isLoading: boolean;
    recordCount: number;
    nameSummary: IAutoCompleteItem | null;
    currency: string;
    isFormSubmit: boolean;

    isOrganisationExpandPanel: boolean;
    isOrganisationPanelDisabled: boolean;
    isOrgnisationPostralAddressExpandPanel: boolean;
    isPersonExpandPanel: boolean;
    isPersonPanelDisabled: boolean;
    isPersonPostalAddressExpandPanel: boolean;
    isCreateMatterExpandPanel: boolean;

    isPerson2ExpandPanel: boolean;
    isPerson2PanelDisabled: boolean;
    isPerson2PostalAddressExpandPanel: boolean;
}
// tslint:disable-next-line: no-anyW
export const CreateClientDialog: React.FC<CreateClientDialogProps> = (props) => {
    const classes = useStyles();

    const organisationGuid = props.enquiryDetail.organisationGuid;
    const organisationText = props.organisation;
    const { nameGuid, clientGuid, secondClientGuid } = props.enquiryDetail;

    // const tenantQuery = useTenant();

    const [state, setState] = useState<CreateClientState>({
        showAlert: false,
        isForceClosed: false,
        showConfirmed: false,
        isFormChanged: false,
        isSwitched: false,
        isLoading: false,
        recordCount: 0,
        nameSummary: null,
        currency: '$',
        isFormSubmit: false,

        isOrganisationExpandPanel: organisationGuid ? false : organisationText ? true : false,
        isOrgnisationPostralAddressExpandPanel: false,
        isPersonExpandPanel: nameGuid || clientGuid ? false : true,
        isPersonPostalAddressExpandPanel: false,
        isCreateMatterExpandPanel: true,
        isOrganisationPanelDisabled: organisationGuid ? true : false,
        isPersonPanelDisabled: nameGuid || clientGuid ? true : false,

        isPerson2ExpandPanel: nameGuid || secondClientGuid ? false : true,
        isPerson2PanelDisabled: nameGuid || secondClientGuid ? true : false,
        isPerson2PostalAddressExpandPanel: false
    });

    const [leadDetailsHistoryForMatterCreation, setLeadDetailsHistoryForMatterCreation] = useState<TaskSummaryList>({
        tasks: [],
        actualRowCountForSearch: 0,
        recordCount: 0,
    });

    // console.log('props.enquiryDetail', props.enquiryDetail);
    // const orgProperties = keys<Organisation>();

    // const {
    //     isCreateClientOpen,
    //     onCreateClientClose,
    //     selectedEnquiry
    // }: // tslint:disable-next-line: no-any
    // any = useContext(EnquirySummaryContext);

    useEffect(() => {
        fetchCurrency();

        if (props.guid) {
            fetchLeadDetailsHistoryForMatterCreation(props.guid);
        }

        if (props.organisation.length > 0) {
            fetchData();
        }
        // tslint:disable-next-line: align
    }, []);

    const fetchCurrency = () => {
        retrieveCurrencySymbolData(
            false,
            // tslint:disable-next-line: no-any
            (data: any) => onDataCurrency(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    };

    const onDataCurrency = (data: CurrencySymbolData) => {
        setState((prevState) => {
            return {
                ...prevState,
                currency: data.options.currencySymbol.symbol,
            };
        });
    };

    const fetchData = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isLoading: true,
            };
        });

        const partyQueryParams: PartyQueryParams = {
            filter: props.organisation,
            offset: 0,
            first: 20,
            includeOrganisation: true,
            includeIndividual: null,
            includeStaff: null,
        };

        retrievePartyData(
            partyQueryParams,
            true, // this.state.isRefresh, -- Since we are updating and creating, we do not fetch cached data
            // tslint:disable-next-line: no-console
            (data) => onRetrieveParty(data),
            // tslint:disable-next-line:no-any
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    };

    const onRetrieveParty = (data: PartyData) => {
        setState((prevState) => {
            return {
                ...prevState,
                recordCount: data.party.nameSummaries.recordCount,
                nameSummary: data.party.nameSummaries.nameSummary.map((source) => ({ label: source.name, value: source.guidID.toString() }))[0],
                isSwitched: data.party.nameSummaries.recordCount > 0 ? false : true,
                isLoading: false,
            };
        });
    };

    const fetchLeadDetailsHistoryForMatterCreation = (guid: string) => {
        var leadQueryParams: LeadDetailsHistoryQueryParams = {
            offset: null,
            first: null,
            filter: null,
            sortColumn: 'CreatedDate',
            sortDirection: 'DESC',
            entityGuidID: guid,
        };

        retrieveLeadDetailsHistoryData(
            leadQueryParams,
            true,
            // tslint:disable-next-line: no-any
            (data: any) => onEnquiryHistoryDataRetrievedForMatterCreation(data),
            // tslint:disable-next-line
            function (reason: any): void {
                showNotification(null, reason, 'error');
            },
        );
    };

    const onEnquiryHistoryDataRetrievedForMatterCreation = (data: LeadDetailsHistoryData) => {
        setLeadDetailsHistoryForMatterCreation(data.task.summaryList);
    };

    const personTitle =
        (props.enquiryDetail.clientTitle === 'None' || props.enquiryDetail.clientTitle?.length === 0) && !props.enquiryDetail.clientFirstName && !props.enquiryDetail.clientLastName
            ? props.title ?? 'None'
            : props.enquiryDetail.clientTitle ?? 'None';

    const initialValues: CreateClientForm = {
        organisation: props.enquiryDetail.organisationGuid ? { value: props.enquiryDetail.organisationGuid, label: props.enquiryDetail.organisation } : null,
        organisationText: props.enquiryDetail.organisation,

        orgAddress: '',
        orgBuildingName: props.enquiryDetail.addressName ? props.enquiryDetail.addressName : '',
        orgUnitLevel: props.enquiryDetail.floor ? props.enquiryDetail.floor : '',
        orgNumber: props.enquiryDetail.number ? props.enquiryDetail.number : '',
        orgStreet1: props.enquiryDetail.street ? props.enquiryDetail.street : '',
        orgStreet2: props.enquiryDetail.clientAddressStreet2 ? props.enquiryDetail.clientAddressStreet2 : '',
        orgCitySuburb: props.enquiryDetail.suburb ? props.enquiryDetail.suburb : '',
        orgState: props.enquiryDetail.state ? props.enquiryDetail.state : '',
        orgPostalCode: props.enquiryDetail.postcode ? props.enquiryDetail.postcode : '',
        orgCountry: props.enquiryDetail.country ? props.enquiryDetail.country : '',

        orgPoBoxType: props.poBoxType,
        orgPostalPOBox: props.enquiryDetail.pOBox ? props.enquiryDetail.pOBox : '',
        orgPostalCitySuburb: props.enquiryDetail.postalSuburb ? props.enquiryDetail.postalSuburb : '',
        orgPostalState: props.enquiryDetail.postalState ? props.enquiryDetail.postalState : '',
        orgPostalPostalCode: props.enquiryDetail.postalPostCode ? props.enquiryDetail.postalPostCode : '',
        orgPostalCountry: props.enquiryDetail.postalCountry ? props.enquiryDetail.postalCountry : '',

        title: personTitle ? personTitle : 'None',
        firstName: props.enquiryDetail.clientFirstName ? props.enquiryDetail.clientFirstName : props.firstName ? props.firstName : '',
        lastName: props.enquiryDetail.clientLastName ? props.enquiryDetail.clientLastName : props.lastName ? props.lastName : '',
        email: props.enquiryDetail.clientEmail ? props.enquiryDetail.clientEmail : props.email ? props.email : '',
        mobile: props.enquiryDetail.clientMobile ? props.enquiryDetail.clientMobile : props.mobile ? props.mobile : '',
        phone: props.enquiryDetail.clientPhone ? props.enquiryDetail.clientPhone : props.phone ? props.phone : '',
        generalNotes:
            props.source && props.sourceNotes
                ? props.source + ' - ' + props.sourceNotes
                : props.source && props.sourceNotes === ''
                ? props.source
                : props.source === '' && props.sourceNotes
                ? props.sourceNotes
                : props.enquiryDetail.clientFirstName && props.enquiryDetail.clientLastName
                ? `${props.firstName} ${props.lastName} ${props.enquiryDetail.clientRelationshipToEnquirer ? `- ${props.enquiryDetail.clientRelationshipToEnquirer}` : ''}`
                : '',
        address: '',
        buildingName: props.enquiryDetail.addressName ? props.enquiryDetail.addressName : '',
        unitLevel: props.enquiryDetail.floor ? props.enquiryDetail.floor : '',
        number: props.enquiryDetail.number ? props.enquiryDetail.number : '',
        street1: props.enquiryDetail.street ? props.enquiryDetail.street : '',
        street2: props.enquiryDetail.clientAddressStreet2 ? props.enquiryDetail.clientAddressStreet2 : '',
        citySuburb: props.enquiryDetail.suburb ? props.enquiryDetail.suburb : '',
        state: props.enquiryDetail.state ? props.enquiryDetail.state : '',
        postalCode: props.enquiryDetail.postcode ? props.enquiryDetail.postcode : '',
        country: props.enquiryDetail.country ? props.enquiryDetail.country : '',
        // postalStreet1: '',
        // postalStreet2: '',
        postalPoBoxType: props.poBoxType,
        postalPOBox: props.enquiryDetail.pOBox ? props.enquiryDetail.pOBox : '',
        postalCitySuburb: props.enquiryDetail.postalSuburb ? props.enquiryDetail.postalSuburb : '',
        postalState: props.enquiryDetail.postalState ? props.enquiryDetail.postalState : '',
        postalPostalCode: props.enquiryDetail.postalPostCode ? props.enquiryDetail.postalPostCode : '',
        postalCountry: props.enquiryDetail.postalCountry ? props.enquiryDetail.postalCountry : '',

        birthDate: props.enquiryDetail.dOB ? props.enquiryDetail.dOB : null,
        gender: props.enquiryDetail.gender ? props.enquiryDetail.gender : null,
        isMarketingConsent: props.enquiryDetail.isMarketingConsent,

        // create matter
        useRecurringMatter: false,
        recurringMatter: null,
        matterCategory: null,
        matterTypeState: null,
        matterType: null,
        // tslint:disable-next-line: max-line-length
        personResponsible:
            props.enquiryDetail && props.enquiryDetail.assignedToIsStaff && props.enquiryDetail.assignedToStaffName && props.enquiryDetail.assignedToStaffGuid
                ? { label: props.enquiryDetail.assignedToStaffName, value: props.enquiryDetail.assignedToStaffGuid }
                : null,
        // tslint:disable-next-line: max-line-length
        personActing:
            props.enquiryDetail && props.enquiryDetail.assignedToIsStaff && props.enquiryDetail.assignedToStaffName && props.enquiryDetail.assignedToStaffGuid
                ? { label: props.enquiryDetail.assignedToStaffName, value: props.enquiryDetail.assignedToStaffGuid }
                : null,
        personAssisting: null,
        credit: null,
        matterTitle: null,
        // tslint:disable-next-line: max-line-length
        referrer:
            props.enquiryDetail && props.enquiryDetail.referrerName && props.enquiryDetail.referrerGuid
                ? { label: props.enquiryDetail.referrerName, value: props.enquiryDetail.referrerGuid }
                : null,
        costAgreementRequired: false,
        estimatedFees: props.enquiryDetail && props.enquiryDetail.estimatedFees ? props.enquiryDetail.estimatedFees : null,
        estimatedDisbursements: props.enquiryDetail && props.enquiryDetail.estimatedDisbursements ? props.enquiryDetail.estimatedDisbursements : null,
        isEstimatedFeesIncludesTax: props.enquiryDetail.isEstimatedFeesIncludesTax,
        isEstimatedDisbursementsIncludesTax: props.enquiryDetail.isEstimatedDisbursementsIncludesTax,
        workObtained: null,
        reportingGroup: null,
        matterTypeTree: null,
        
        secondPersonTitle: props.enquiryDetail.secondClientTitle ? props.enquiryDetail.secondClientTitle : 'None',
        secondPersonFirstName: props.enquiryDetail.secondClientFirstName ? props.enquiryDetail.secondClientFirstName : '',
        secondPersonLastName: props.enquiryDetail.secondClientLastName ? props.enquiryDetail.secondClientLastName : '',
        secondPersonEmail: props.enquiryDetail.secondClientEmail ? props.enquiryDetail.secondClientEmail : '',
        secondPersonMobile: props.enquiryDetail.secondClientMobile ? props.enquiryDetail.secondClientMobile : '',
        secondPersonPhone: props.enquiryDetail.secondClientPhone ? props.enquiryDetail.secondClientPhone : '',
        secondPersonGeneralNotes:
            props.source && props.sourceNotes
                ? props.source + ' - ' + props.sourceNotes
                : props.source && props.sourceNotes === ''
                ? props.source
                : props.source === '' && props.sourceNotes
                ? props.sourceNotes
                : props.enquiryDetail.secondClientFirstName && props.enquiryDetail.secondClientLastName
                ? `${props.firstName} ${props.lastName} ${props.enquiryDetail.secondClientRelationshipToEnquirer ? `- ${props.enquiryDetail.secondClientRelationshipToEnquirer}` : ''}`
                : '',
        secondPersonAddress: '',
        secondPersonBuildingName: props.enquiryDetail.secondClientAddressName ? props.enquiryDetail.secondClientAddressName : '',
        secondPersonUnitLevel: props.enquiryDetail.secondClientFloor ? props.enquiryDetail.secondClientFloor : '',
        secondPersonNumber: props.enquiryDetail.secondClientNumber ? props.enquiryDetail.secondClientNumber : '',
        secondPersonStreet1: props.enquiryDetail.secondClientStreet1 ? props.enquiryDetail.secondClientStreet1 : '',
        secondPersonStreet2: props.enquiryDetail.secondClientStreet2 ? props.enquiryDetail.secondClientStreet2 : '',
        secondPersonCitySuburb: props.enquiryDetail.secondClientSuburb ? props.enquiryDetail.secondClientSuburb : '',
        secondPersonState: props.enquiryDetail.secondClientState ? props.enquiryDetail.secondClientState : '',
        secondPersonPostalCode: props.enquiryDetail.secondClientPostCode ? props.enquiryDetail.secondClientPostCode : '',
        secondPersonCountry: props.enquiryDetail.secondClientCountry ? props.enquiryDetail.secondClientCountry : '',

        secondPersonPostalPoBoxType: props.enquiryDetail.secondClientPOBoxType ?? props.poBoxType,
        secondPersonPostalPOBox: props.enquiryDetail.secondClientPOBox ? props.enquiryDetail.secondClientPOBox : '',
        secondPersonPostalCitySuburb: props.enquiryDetail.secondClientPostalSuburb ? props.enquiryDetail.secondClientPostalSuburb : '',
        secondPersonPostalState: props.enquiryDetail.secondClientPostalState ? props.enquiryDetail.secondClientPostalState : '',
        secondPersonPostalPostalCode: props.enquiryDetail.secondClientPostalPostCode ? props.enquiryDetail.secondClientPostalPostCode : '',
        secondPersonPostalCountry: props.enquiryDetail.secondClientPostalCountry ? props.enquiryDetail.secondClientPostalCountry : '',

        secondPersonBirthDate: props.enquiryDetail.secondClientDOB ? props.enquiryDetail.secondClientDOB : null,
        secondPersonGender: props.enquiryDetail.secondClientGender ? props.enquiryDetail.secondClientGender : null,
        secondPersonIsMarketingConsent: props.enquiryDetail.secondClientIsMarketingConsent,

        isNameFromDataSource: props.enquiryDetail.isNameFromDataSource,
        isOrganisationFromDataSource: props.enquiryDetail.isOrganisationFromDataSource,
        isOtherSideFromDataSource: props.enquiryDetail.isOtherSideFromDataSource,
        isSecondClientFromDataSource: props.enquiryDetail.isSecondClientFromDataSource,
    };

    const canCreateMatter = props.enquiryActions ? props.enquiryActions.canCreateMatter : false;

    const createClientTitle = props.enquiryActions?.canCreateContactForLostEnquiry ? 'Create Contact' : `Create ${canCreateMatter ? 'Matter' : 'Client'}`;
    const createClientMessage = props.enquiryActions?.canCreateContactForLostEnquiry
        ? 'Are you sure you want to create a contact?'
        : `Are you sure you want to create a ${canCreateMatter ? 'matter' : 'client'}?`;

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    // tslint:disable-next-line: no-any
    const isPoBoxRequired = (values: any, isOrganisation: boolean) => {
        if (isOrganisation) {
            if (values.orgPostalCitySuburb || values.orgPostalState || values.orgPostalPostalCode || values.orgPostalCountry) {
                return true;
            }
        } else {
            if (values.postalCitySuburb || values.postalState || values.postalPostalCode || values.postalCountry) {
                return true;
            }
        }

        return false;
    };

    // tslint:disable-next-line: no-any
    const isSecondClientPoBoxRequired = (values: any) => {
        if (values.secondPersonPostalCitySuburb || values.secondPersonPostalState || values.secondPersonPostalPostalCode || values.secondPersonPostalCountry) {
            return true;
        }
        return false;
    };

    // tslint:disable-next-line: no-any
    const onCloseDrawer = (/*form: FormApi<CreateClientForm>, values: any, isForceClose: boolean*/) => () => {
        if (state.isFormChanged && !state.isForceClosed) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showAlert: true,
                };
            });
        } else {
            onCreateClientClosed();
        }
    };

    // tslint:disable-next-line
    const onFormValueChanged = (form: FormApi<CreateClientForm>, props: any) => {
        if (!props.pristine) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (form: FormApi<CreateClientForm>, agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: false,
                    showAlert: false,
                };
            });

            onCreateClientClosed();
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    showAlert: false,
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const closePanel = (form: FormApi<CreateClientForm>, values: any) => {
        if (state.isFormChanged && !state.isForceClosed) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showAlert: true,
                };
            });
        } else {
            onCreateClientClosed();
        }
    };

    const onSwitch = () => {
        setState((prevState) => {
            return {
                ...prevState,
                isSwitched: !prevState.isSwitched,
            };
        });
    };

    // tslint:disable-next-line
    // const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // tslint:disable-next-line: no-any
    // const EmailValidateWithRequired = (value: any) => {
    //     let error;

    //     if (value === undefined) {
    //         return value ? undefined : 'Email is required';
    //     } else if (value.length === 0) {
    //         return value ? undefined : 'Email is required';
    //     } else if (!emailRegex.test(value)) {
    //         error = 'Email is not valid';
    //     }
    //     return error;
    // };

    const onClickSave = (form: FormApi<CreateClientForm>, values: CreateClientForm) => {
        setState((prevState) => {
            return {
                ...prevState,
                isFormSubmit: true,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const onReset = (form: FormApi<CreateClientForm>, values: CreateClientForm) => {
        // tslint:disable-next-line: no-unused-expression
        form.reset();
    };

    // tslint:disable-next-line: no-any max-line-length
    const onSelection = (form: FormApi<CreateClientForm>, values: AnyObject) => (placeType: PlaceType | null, address: Address, name: string) => {
        if (name === 'orgStreet1') {
            form.change('orgCountry', address.country ? address.country : values.orgCountry ? values.orgCountry : '');
            form.change('orgPostalCode', address.postalCode ? address.postalCode : values.orgPostalCode ? values.orgPostalCode : '');
            form.change('orgCitySuburb', address.locality ? address.locality : values.orgCitySuburb ? values.orgCitySuburb : '');
            // tslint:disable-next-line: max-line-length
            form.change('orgState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.orgState ? values.orgState : '');
            form.change('orgBuildingName', address.premise ? address.premise : values.orgBuildingName ? values.orgBuildingName : '');
            // form.change('orgStreet1', placeType?.structured_formatting.main_text);
            form.change('orgStreet1', address.route ? address.route : values.orgStreet1 ? values.orgStreet1 : '');
            form.change('orgNumber', address.streetNumber ? address.streetNumber : values.orgNumber ? values.orgNumber : '');
            form.change('orgUnitLevel', address.floor ? address.floor : values.orgUnitLevel ? values.orgUnitLevel : '');
        }

        if (name === 'orgStreet2') {
            form.change('orgStreet2', placeType?.structured_formatting.main_text);
        }

        // if (name === 'orgPostalStreet1') {
        //     form.change('orgPostalCountry', address.country ? address.country : values.orgPostalCountry ? values.orgPostalCountry : '');
        // tslint:disable-next-line: max-line-length
        //     form.change('orgPostalPostalCode', address.postalCode ? address.postalCode : values.orgPostalPostalCode ? values.orgPostalPostalCode : '');
        //     form.change('orgPostalCitySuburb', address.locality ? address.locality : values.orgPostalCitySuburb ? values.orgPostalCitySuburb : '');
        // tslint:disable-next-line: max-line-length
        //     form.change('orgPostalState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.orgPostalState ? values.orgPostalState : '');
        //     tslint:disable-next-line: max-line-length
        //     form.change('orgPostalBuildingName', address.premise ? address.premise : values.orgPostalBuildingName ? values.orgPostalBuildingName : '');
        //     form.change('orgPostalStreet1', placeType?.structured_formatting.main_text);
        //     form.change('orgPostalNumber', address.floor ? address.floor : values.orgPostalNumber ? values.orgPostalNumber : '');
        // }
        // if (name === 'orgPostalStreet2') {
        //     form.change('orgPostalStreet2', placeType?.structured_formatting.main_text);
        // }

        if (name === 'street1') {
            form.change('country', address.country ? address.country : values.country ? values.country : '');
            form.change('postalCode', address.postalCode ? address.postalCode : values.postalCode ? values.postalCode : '');
            form.change('citySuburb', address.locality ? address.locality : values.citySuburb ? values.citySuburb : '');
            // tslint:disable-next-line: max-line-length
            form.change('state', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.state ? values.state : '');
            form.change('buildingName', address.premise ? address.premise : values.buildingName ? values.buildingName : '');
            // form.change('street1', placeType?.structured_formatting.main_text);
            form.change('street1', address.route ? address.route : values.street1 ? values.street1 : '');
            form.change('number', address.streetNumber ? address.streetNumber : values.number ? values.number : '');
            form.change('unitLevel', address.floor ? address.floor : values.unitLevel ? values.unitLevel : '');
        }

        if (name === 'street2') {
            form.change('street2', placeType?.structured_formatting.main_text);
        }

        // if (name === 'postalStreet1') {
        //     form.change('postalCountry', address.country ? address.country : values.postalCountry ? values.postalCountry : '');
        //     form.change('postalPostalCode', address.postalCode ? address.postalCode : values.postalPostalCode ? values.postalPostalCode : '');
        //     form.change('postalCitySuburb', address.locality ? address.locality : values.postalCitySuburb ? values.postalCitySuburb : '');
        //     // tslint:disable-next-line: max-line-length
        //     form.change('postalState', address.administrativeAreaLevel1
        // ? address.administrativeAreaLevel1 : values.postalState ? values.postalState : '');
        //     form.change('postalBuildingName', address.premise ? address.premise : values.postalBuildingName ? values.postalBuildingName : '');
        //     form.change('postalStreet1', placeType?.structured_formatting.main_text);
        //     form.change('postalNumber', address.floor ? address.floor : values.postalNumber ? values.postalNumber : '');
        // }
        // if (name === 'postalStreet2') {
        //     form.change('postalStreet2', placeType?.structured_formatting.main_text);
        // }

        if (name === 'orgPostalCitySuburb') {
            form.change('orgPostalCitySuburb', address.locality ? address.locality : values.orgPostalCitySuburb ? values.orgPostalCitySuburb : '');
            form.change('orgPostalPostalCode', address.postalCode ? address.postalCode : values.orgPostalPostalCode ? values.orgPostalPostalCode : '');
            form.change('orgPostalCountry', address.country ? address.country : values.orgPostalCountry ? values.orgPostalCountry : '');
            form.change('orgPostalState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.orgPostalState ? values.orgPostalState : '');
        }

        if (name === 'postalCitySuburb') {
            form.change('postalCitySuburb', address.locality ? address.locality : values.postalCitySuburb ? values.postalCitySuburb : '');
            form.change('postalPostalCode', address.postalCode ? address.postalCode : values.postalPostalCode ? values.postalPostalCode : '');
            form.change('postalCountry', address.country ? address.country : values.postalCountry ? values.postalCountry : '');
            form.change('postalState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.postalState ? values.postalState : '');
        }

        // person 2
        if (name === 'secondPersonStreet1') {
            form.change('secondPersonCountry', address.country ? address.country : values.secondPersonCountry ? values.secondPersonCountry : '');
            form.change('secondPersonPostalCode', address.postalCode ? address.postalCode : values.secondPersonPostalCode ? values.secondPersonPostalCode : '');
            form.change('secondPersonCitySuburb', address.locality ? address.locality : values.secondPersonCitySuburb ? values.secondPersonCitySuburb : '');
            // tslint:disable-next-line: max-line-length
            form.change('secondPersonState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : values.secondPersonState ? values.secondPersonState : '');
            form.change('secondPersonBuildingName', address.premise ? address.premise : values.secondPersonBuildingName ? values.secondPersonBuildingName : '');
            // form.change('street1', placeType?.structured_formatting.main_text);
            form.change('secondPersonStreet1', address.route ? address.route : values.secondPersonStreet1 ? values.secondPersonStreet1 : '');
            form.change('secondPersonNumber', address.streetNumber ? address.streetNumber : values.secondPersonNumber ? values.secondPersonNumber : '');
            form.change('secondPersonUnitLevel', address.floor ? address.floor : values.secondPersonUnitLevel ? values.secondPersonUnitLevel : '');
        }

        if (name === 'secondPersonStreet2') {
            form.change('secondPersonStreet2', placeType?.structured_formatting.main_text);
        }

        if (name === 'secondPersonPostalCitySuburb') {
            form.change('secondPersonPostalCitySuburb', address.locality ? address.locality : values.secondPersonPostalCitySuburb ? values.secondPersonPostalCitySuburb : '');
            form.change('secondPersonPostalPostalCode', address.postalCode ? address.postalCode : values.secondPersonPostalPostalCode ? values.secondPersonPostalPostalCode : '');
            form.change('secondPersonPostalCountry', address.country ? address.country : values.secondPersonPostalCountry ? values.secondPersonPostalCountry : '');
            form.change('secondPersonPostalState', address.administrativeAreaLevel1 ? address.administrativeAreaLevel1 : 
                                                    values.secondPersonPostalState ? values.secondPersonPostalState : '');
        }

    };

    const onDropDownSelect = (
        // tslint:disable-next-line: no-any
        form: FormApi<CreateClientForm>,
        // tslint:disable-next-line: no-any
        selection: IAutoCompleteItem,
        name: string,
    ) => {
        // input.OnChange(selection.value);
        if (name === 'organisation') {
            form.change('organisation', selection);
        }
        if (name === 'matterType') {
            form.change('matterType', selection);
        }
    };

    // tslint:disable-next-line: no-any
    const onInputChanged = (form: FormApi<CreateClientForm>, name: string | any) => (value: string) => {
        form.change(name, value);
    };

    // tslint:disable-next-line: no-any
    const onSubmit = (form: FormApi<CreateClientForm>, values: CreateClientForm) => {
        setState((prevState) => {
            return {
                ...prevState,
                showConfirmed: true,
            };
        });
    };

    // tslint:disable-next-line: no-any
    const onConfirmed = (form: FormApi<CreateClientForm>, values: any, agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showConfirmed: false,
                };
            });

            setTimeout(() => {
                if (props.sourceSystem?.toLowerCase() === 'leap') {
                    if (
                        (state.isSwitched || !(values.isOrganisationFromDataSource ?? true)) &&
                        (values.organisationText !== null || values.organisationText !== undefined || values.organisationText.trim() !== '')
                    ) {
                        // create organisation and add new person
                        createCreateAndContact(form, values);
                    } else if (!state.isSwitched && values.organisation !== null) {
                        // add new person to existing organisation
                        createCreateClientContact(form, values);
                    } else {
                        // person only
                        createContact(form, values);
                    }
                } else {
                    closeForNonLeap(form, values);
                }

                setState((prevState) => {
                    return {
                        ...prevState,
                        isForceClosed: true,
                    };
                });

                onCreateClientClosed();
                // tslint:disable-next-line: align
            }, 500);
        } else {
            setState((prevState) => {
                return {
                    ...prevState,
                    showConfirmed: false,
                };
            });
        }
    };

    // create organisation and add new person
    const createCreateAndContact = (form: FormApi<CreateClientForm>, values: CreateClientForm) => {
        // Generate the history Document.
        let documentBlob: Blob | undefined;
        HistoryFile(props.enquiryDetail, 'DESC', state.currency, props.timezone, leadDetailsHistoryForMatterCreation, (results) => {
            // console.log('blob in getHistoryStringFile', results);
            documentBlob = results;
            saveCreateAndContact(form, values, documentBlob);
        },
        props.customFieldByHierarchy ? props.customFieldByHierarchy : []);
    };

    // add new person to existing organisation
    const createCreateClientContact = (form: FormApi<CreateClientForm>, values: CreateClientForm) => {
        // Generate the history Document.
        let documentBlob: Blob | undefined;
        HistoryFile(props.enquiryDetail, 'DESC', state.currency, props.timezone, leadDetailsHistoryForMatterCreation, (results) => {
            // console.log('blob in getHistoryStringFile', results);
            documentBlob = results;
            saveCreateClientContact(form, values, documentBlob);
        },
        props.customFieldByHierarchy ? props.customFieldByHierarchy : []);
    };

    // person only
    const createContact = (form: FormApi<CreateClientForm>, values: CreateClientForm) => {
        // Generate the history Document.
        let documentBlob: Blob | undefined;
        HistoryFile(props.enquiryDetail, 'DESC', state.currency, props.timezone, leadDetailsHistoryForMatterCreation, (results) => {
            // console.log('blob in getHistoryStringFile', results);
            documentBlob = results;
            saveCreateClient(form, values, documentBlob);
        },
        props.customFieldByHierarchy ? props.customFieldByHierarchy : []);
    };

    const saveCreateClient = (form: FormApi<CreateClientForm>, values: CreateClientForm, historyDocument?: Blob) => {
        client
            .mutate({
                mutation: CreateContactMutation,
                variables: {
                    input: getCreateContactInput(values, historyDocument),
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        closePanel(form, values);
                    } else {
                        showNotification('Failed to Save Create Contact', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Create Contact', reason, 'error');
            });
    };

    const saveCreateClientContact = (form: FormApi<CreateClientForm>, values: CreateClientForm, historyDocument?: Blob) => {
        client
            .mutate({
                mutation: CreateClientContactMutation,
                variables: {
                    input: getCreateClientContactInput(values, historyDocument),
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        closePanel(form, values);
                    } else {
                        showNotification('Failed to Save Create Client Contact', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Create Client Contact', reason, 'error');
            });
    };

    const saveCreateAndContact = (form: FormApi<CreateClientForm>, values: CreateClientForm, historyDocument?: Blob) => {
        // Save the File
        client
            .mutate({
                mutation: CreateClientAndContactMutation,
                variables: {
                    input: getCreateClientAndContactInput(values, historyDocument),
                },
            })
            // tslint:disable-next-line: no-any
            .then((results: { data: any }) => {
                if (results.data) {
                    if (results.data.error === null || results.data.error === undefined) {
                        showNotification(null, 'Successfully submitted', 'info');
                        closePanel(form, values);
                    } else {
                        showNotification('Failed to Save Create Client and Contact', results.data.error, 'error');
                    }
                }
            })
            // tslint:disable-next-line:no-any
            .catch((reason: any) => {
                showNotification('Failed to Save Create Client and Contact', reason, 'error');
            });
    };

    const closeForNonLeap = (form: FormApi<CreateClientForm>, values: CreateClientForm) => {
        closePanel(form, values);
        showNotification('Integration warning!', 'Integration is not completed for this Tenant!', 'warning');
    };

    // Creating a new organisation and add a new person to it.
    const getCreateClientAndContactInput = (values: CreateClientForm, historyDocument?: Blob) => {

        // const matterTypeId = tenantQuery.data?.tenant.tenantId === '0010' ||
        // tenantQuery.data?.tenant.tenantId === '0099' ? values.matterTypeTree?.value : values.matterType?.value;
        const matterTypeId = values.matterTypeTree?.value;

        let birthDate = formatDateString(values.birthDate);
        let secondClientDobFinal = formatDateString(values.secondPersonBirthDate);
        let otherPartyDob = formatDateString(props.enquiryDetail.otherPartyDOB);
        // tslint:disable-next-line: no-shadowed-variable
        const nameGuid = getNameGuid(props.enquiryDetail);

        return {
            enquiryGuid: props.guid,

            companyGuid: props.enquiryDetail.organisationGuid,
            nameGuid: nameGuid,

            companyName: values.organisationText,
            orgAddressBuildingName: values.orgBuildingName,
            orgAddressLevel: values.orgUnitLevel,
            orgAddressNumber: values.orgNumber,
            orgAddressStreet1: values.orgStreet1,
            orgAddressStreet2: values.orgStreet2,
            orgAddressSuburb: values.orgCitySuburb,
            orgAddressState: values.orgState,
            orgAddressPostCode: values.orgPostalCode,
            orgAddressCountry: values.orgCountry,

            orgPostalPoBoxType: values.orgPoBoxType,
            orgPostalPoBox: values.orgPostalPOBox,
            orgPostalSuburb: values.orgPostalCitySuburb,
            orgPostalState: values.orgPostalState,
            orgPostalPostCode: values.orgPostalPostalCode,
            orgPostalCountry: values.orgPostalCountry,

            title: values.title === 'None' ? '' : values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobile: values.mobile,
            phone: values.phone,
            generalNotes: values.generalNotes,
            isMarketingConsent: values.isMarketingConsent,
            reportingGroupGuid: values.reportingGroup ? values.reportingGroup.value : null,
            workObtained: values.workObtained ? values.workObtained : null,

            recurringMatterTemplateId: values.recurringMatter?.value,
            matterTypeId: matterTypeId,
            matterTypeState: values.matterTypeState?.label,
            personResponsible: values.personResponsible?.value,
            personActing: values.personActing?.value,
            personAssisting: values.personAssisting?.value,
            personCredit: values.credit?.value,
            matterTitle: values.matterTitle,
            referrerGuid: values.referrer?.value,
            isCreditAgreementRequired: values.costAgreementRequired,
            estimatedFees: values.estimatedFees,
            isEstimatedFeesIncludesTax: values.isEstimatedFeesIncludesTax,
            estimatedDisb: values.estimatedDisbursements,
            isEstimatedDisbIncludeTax: values.isEstimatedDisbursementsIncludesTax,

            gender: values.gender,
            birthDate: birthDate,
            
            otherPartyTitle: props.enquiryDetail.otherPartyTitle === 'None' ? '' : props.enquiryDetail.otherPartyTitle,
            clientRelationshipToEnquirer: props.enquiryDetail.clientRelationshipToEnquirer,
            otherPartyFirstName: props.enquiryDetail.otherPartyFirstName,
            otherPartyLastName: props.enquiryDetail.otherPartyLastName,
            otherPartyNotes: props.enquiryDetail.otherPartyNotes,
            otherPartyOrganisation: props.enquiryDetail.otherPartyOrganisation,
            otherPartyEmail: props.enquiryDetail.otherPartyEmail,
            otherPartyPhone: props.enquiryDetail.otherPartyPhone,
            otherPartyMobile: props.enquiryDetail.otherPartyMobile,
            otherPartyDOB: otherPartyDob,
            otherPartyAddressName: props.enquiryDetail.otherPartyAddressName,
            otherPartyFloor: props.enquiryDetail.otherPartyFloor,
            otherPartyNumber: props.enquiryDetail.otherPartyNumber,
            otherPartyStreet: props.enquiryDetail.otherPartyStreet,
            otherPartyStreet2: props.enquiryDetail.otherPartyStreet2,
            otherPartySuburb: props.enquiryDetail.otherPartySuburb,
            otherPartyState: props.enquiryDetail.otherPartyState,
            otherPartyPostcode: props.enquiryDetail.otherPartyPostcode,
            otherPartyCountry: props.enquiryDetail.otherPartyCountry,

            otherPartyPOBoxType: props.enquiryDetail.otherPartyPOBoxType,
            otherPartyPOBox: props.enquiryDetail.otherPartyPOBox,
            otherPartyPostalSuburb: props.enquiryDetail.otherPartyPostalSuburb,
            otherPartyPostalState: props.enquiryDetail.otherPartyPostalState,
            otherPartyPostalPostCode: props.enquiryDetail.otherPartyPostalPostCode,
            otherPartyPostalCountry: props.enquiryDetail.otherPartyPostalCountry,
            otherPartyNameGuid: props.enquiryDetail.otherPartyNameGuid,
            otherPartyCompanyGuid: props.enquiryDetail.otherPartyOrganisationGuid,

            historyFile: {
                file: historyDocument,
                name: 'RedView CRM Enquiry History.pdf', // if this is renamed, make sure to change a condition in the mutation as well.
                guidID: null,
                status: 1, // new file.
            },

            // second person
            secondClientPhone: values.secondPersonPhone,
            secondClientEmail: values.secondPersonEmail,
            secondClientTitle: values.secondPersonTitle === 'None' ? '' : values.secondPersonTitle,
            secondClientMobile: values.secondPersonMobile,
            secondClientFirstName: values.secondPersonFirstName ? values.secondPersonFirstName : null,
            secondClientLastName: values.secondPersonLastName ? values.secondPersonLastName : null,
            secondClientGuid: props.enquiryDetail.secondClientGuid,
            secondClientRelationshipToEnquirer: props.enquiryDetail.secondClientRelationshipToEnquirer,
            secondClientDOB: secondClientDobFinal,
            secondClientGender: values.secondPersonGender ?? null,
            secondClientIsMarketingConsent: values.secondPersonIsMarketingConsent,
            secondClientIsTermsAndConditionsApproved: props.enquiryDetail.secondClientIsTermsAndConditionsApproved,
            secondClientIsPrivacyStatementProvided: props.enquiryDetail.secondClientIsPrivacyStatementProvided,
            secondClientIsConflictCheckPerformed: props.enquiryDetail.secondClientIsConflictCheckPerformed,
            secondClientIsIdentityCheckPerformed: props.enquiryDetail.secondClientIsIdentityCheckPerformed,
            secondClientIsNoConflict: props.enquiryDetail.secondClientIsNoConflict,
            secondClientAddressName: values.secondPersonAddress ?? null,
            secondClientFloor: values.secondPersonUnitLevel ?? null,
            secondClientNumber: values.secondPersonNumber ?? null,
            secondClientStreet1: values.secondPersonStreet1 ?? null,
            secondClientStreet2: values.secondPersonStreet2 ?? null,
            secondClientSuburb: values.secondPersonCitySuburb ?? null,
            secondClientState: values.secondPersonState ?? null,
            secondClientPostCode: values.secondPersonPostalCode ?? null,
            secondClientCountry: values.secondPersonCountry ?? null,
            secondClientPOBoxType: values.secondPersonPostalPoBoxType ?? null,
            secondClientPOBox: values.secondPersonPostalPOBox ?? null,
            secondClientPostalSuburb: values.secondPersonPostalCitySuburb ?? null,
            secondClientPostalState: values.secondPersonPostalState ?? null,
            secondClientPostalPostCode: values.secondPersonPostalPostalCode ?? null,
            secondClientPostalCountry: values.secondPersonPostalCountry ?? null,

            isNameFromDataSource: values.isNameFromDataSource ?? props.enquiryDetail.isNameFromDataSource ?? null,
            isOrganisationFromDataSource: values.isOrganisationFromDataSource ?? props.enquiryDetail.isOrganisationFromDataSource ?? null,
            isOtherSideFromDataSource: values.isOtherSideFromDataSource ?? props.enquiryDetail.isOtherSideFromDataSource ?? null,
            isSecondClientFromDataSource: values.isSecondClientFromDataSource ?? props.enquiryDetail.isSecondClientFromDataSource ?? null
        };
    };

    const getNameGuid = (enquiryDetail: EnquiryDetail) => {
        // tslint:disable-next-line: no-shadowed-variable
        const { clientGuid, clientFirstName, clientLastName, nameGuid } = enquiryDetail;
        
        let tempNameGuid: string | null = null;

        if (clientGuid) {
            tempNameGuid = clientGuid;
        } else if (!clientGuid && !clientFirstName && !clientLastName && nameGuid) {
            tempNameGuid = nameGuid;
        } else {
            tempNameGuid = null;
        }

        return tempNameGuid;
    };

    // Add new Person to a existing Organisation
    const getCreateClientContactInput = (values: CreateClientForm, historyDocument?: Blob) => {

        // const matterTypeId = tenantQuery.data?.tenant.tenantId === '0010' 
        // || tenantQuery.data?.tenant.tenantId === '0099' ? values.matterTypeTree?.value : values.matterType?.value;

        let birthDate = formatDateString(values.birthDate);
        let secondClientDobFinal = formatDateString(values.secondPersonBirthDate);
        let otherPartyDob = formatDateString(props.enquiryDetail.otherPartyDOB);

        const matterTypeId = values.matterTypeTree?.value;

        // tslint:disable-next-line: no-shadowed-variable
        const nameGuid = getNameGuid(props.enquiryDetail);

        return {
            enquiryGuid: props.guid,

            companyGuid: values.organisation?.value, // Organisation Guid.
            nameGuid: nameGuid,

            title: values.title === 'None' ? '' : values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobile: values.mobile,
            phone: values.phone,
            generalNotes: values.generalNotes,
            isMarketingConsent: values.isMarketingConsent,
            reportingGroupGuid: values.reportingGroup ? values.reportingGroup.value : null,
            workObtained: values.workObtained ? values.workObtained : null,

            recurringMatterTemplateId: values.recurringMatter?.value,
            matterTypeId: matterTypeId,
            matterTypeState: values.matterTypeState?.label,
            personResponsible: values.personResponsible?.value,
            personActing: values.personActing?.value,
            personAssisting: values.personAssisting?.value,
            personCredit: values.credit?.value,
            matterTitle: values.matterTitle,
            referrerGuid: values.referrer?.value,
            isCreditAgreementRequired: values.costAgreementRequired,
            estimatedFees: values.estimatedFees,
            isEstimatedFeesIncludesTax: values.isEstimatedFeesIncludesTax,
            estimatedDisb: values.estimatedDisbursements,
            isEstimatedDisbIncludeTax: values.isEstimatedDisbursementsIncludesTax,

            gender: values.gender,
            birthDate: birthDate,

            otherPartyTitle: props.enquiryDetail.otherPartyTitle === 'None' ? '' : props.enquiryDetail.otherPartyTitle,
            clientRelationshipToEnquirer: props.enquiryDetail.clientRelationshipToEnquirer,
            otherPartyFirstName: props.enquiryDetail.otherPartyFirstName,
            otherPartyLastName: props.enquiryDetail.otherPartyLastName,
            otherPartyNotes: props.enquiryDetail.otherPartyNotes,
            otherPartyOrganisation: props.enquiryDetail.otherPartyOrganisation,
            otherPartyEmail: props.enquiryDetail.otherPartyEmail,
            otherPartyPhone: props.enquiryDetail.otherPartyPhone,
            otherPartyMobile: props.enquiryDetail.otherPartyMobile,

            otherPartyDOB: otherPartyDob,
            otherPartyAddressName: props.enquiryDetail.otherPartyAddressName,
            otherPartyFloor: props.enquiryDetail.otherPartyFloor,
            otherPartyNumber: props.enquiryDetail.otherPartyNumber,
            otherPartyStreet: props.enquiryDetail.otherPartyStreet,
            otherPartyStreet2: props.enquiryDetail.otherPartyStreet2,
            otherPartySuburb: props.enquiryDetail.otherPartySuburb,
            otherPartyState: props.enquiryDetail.otherPartyState,
            otherPartyPostcode: props.enquiryDetail.otherPartyPostcode,
            otherPartyCountry: props.enquiryDetail.otherPartyCountry,

            otherPartyPOBoxType: props.enquiryDetail.otherPartyPOBoxType,
            otherPartyPOBox: props.enquiryDetail.otherPartyPOBox,
            otherPartyPostalSuburb: props.enquiryDetail.otherPartyPostalSuburb,
            otherPartyPostalState: props.enquiryDetail.otherPartyPostalState,
            otherPartyPostalPostCode: props.enquiryDetail.otherPartyPostalPostCode,
            otherPartyPostalCountry: props.enquiryDetail.otherPartyPostalCountry,
            otherPartyNameGuid: props.enquiryDetail.otherPartyNameGuid,
            otherPartyCompanyGuid: props.enquiryDetail.otherPartyOrganisationGuid,

            historyFile: {
                file: historyDocument,
                name: 'RedView CRM Enquiry History.pdf', // if this is renamed, make sure to change a condition in the mutation as well.
                guidID: null,
                status: 1, // new file.
            },

            // secondPerson
            secondClientPhone: values.secondPersonPhone,
            secondClientEmail: values.secondPersonEmail,
            secondClientTitle: values.secondPersonTitle === 'None' ? '' : values.secondPersonTitle,
            secondClientMobile: values.secondPersonMobile,
            secondClientFirstName: values.secondPersonFirstName ? values.secondPersonFirstName : null,
            secondClientLastName: values.secondPersonLastName ? values.secondPersonLastName : null,
            secondClientGuid: props.enquiryDetail.secondClientGuid,
            secondClientRelationshipToEnquirer: props.enquiryDetail.secondClientRelationshipToEnquirer,
            secondClientDOB: secondClientDobFinal,
            secondClientGender: values.secondPersonGender ?? null,
            secondClientIsMarketingConsent: values.secondPersonIsMarketingConsent,
            secondClientIsTermsAndConditionsApproved: props.enquiryDetail.secondClientIsTermsAndConditionsApproved,
            secondClientIsPrivacyStatementProvided: props.enquiryDetail.secondClientIsPrivacyStatementProvided,
            secondClientIsConflictCheckPerformed: props.enquiryDetail.secondClientIsConflictCheckPerformed,
            secondClientIsIdentityCheckPerformed: props.enquiryDetail.secondClientIsIdentityCheckPerformed,
            secondClientIsNoConflict: props.enquiryDetail.secondClientIsNoConflict,
            secondClientAddressName: values.secondPersonAddress ?? null,
            secondClientFloor: values.secondPersonUnitLevel ?? null,
            secondClientNumber: values.secondPersonNumber ?? null,
            secondClientStreet1: values.secondPersonStreet1 ?? null,
            secondClientStreet2: values.secondPersonStreet2 ?? null,
            secondClientSuburb: values.secondPersonCitySuburb ?? null,
            secondClientState: values.secondPersonState ?? null,
            secondClientPostCode: values.secondPersonPostalCode ?? null,
            secondClientCountry: values.secondPersonCountry ?? null,
            secondClientPOBoxType: values.secondPersonPostalPoBoxType ?? null,
            secondClientPOBox: values.secondPersonPostalPOBox ?? null,
            secondClientPostalSuburb: values.secondPersonPostalCitySuburb ?? null,
            secondClientPostalState: values.secondPersonPostalState ?? null,
            secondClientPostalPostCode: values.secondPersonPostalPostalCode ?? null,
            secondClientPostalCountry: values.secondPersonPostalCountry ?? null,

            isNameFromDataSource: values.isNameFromDataSource ?? props.enquiryDetail.isNameFromDataSource ?? null,
            isOrganisationFromDataSource: values.isOrganisationFromDataSource ?? props.enquiryDetail.isOrganisationFromDataSource ?? null,
            isOtherSideFromDataSource: values.isOtherSideFromDataSource ?? props.enquiryDetail.isOtherSideFromDataSource ?? null,
            isSecondClientFromDataSource: values.isSecondClientFromDataSource ?? props.enquiryDetail.isSecondClientFromDataSource ?? null
        };
    };

    // Person only
    const getCreateContactInput = (values: CreateClientForm, historyDocument?: Blob) => {
        // const pdfFile = HistoryFile(props.enquiryDetail, 'DESC', state.currency, props.timezone,
        //                             props.leadDetailsHistory, HistoryActivityWidth.FULL_WIDTH);

        // const matterTypeId = tenantQuery.data?.tenant.tenantId === '0010' 
        // || tenantQuery.data?.tenant.tenantId === '0099' ? values.matterTypeTree?.value : values.matterType?.value;
        const matterTypeId = values.matterTypeTree?.value;

        let birthDate = formatDateString(values.birthDate);
        let secondClientDobFinal = formatDateString(values.secondPersonBirthDate);
        let otherPartyDob = formatDateString(props.enquiryDetail.otherPartyDOB);

        // tslint:disable-next-line: no-shadowed-variable
        const nameGuid = getNameGuid(props.enquiryDetail);

        return {
            enquiryGuid: props.guid,

            nameGuid: nameGuid,

            title: values.title === 'None' ? '' : values.title,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobile: values.mobile,
            phone: values.phone,
            generalNotes: values.generalNotes,
            reportingGroupGuid: values.reportingGroup ? values.reportingGroup.value : null,
            workObtained: values.workObtained ? values.workObtained : null,

            personAddressBuildingName: values.buildingName,
            personAddressLevel: values.unitLevel,
            personAddressNumber: values.number,
            personAddressStreet1: values.street1,
            personAddressStreet2: values.street2,
            personAddressSuburb: values.citySuburb,
            personAddressState: values.state,
            personAddressPostCode: values.postalCode,
            personAddressCountry: values.country,

            personPostalPoBoxType: values.postalPoBoxType,
            personPostalPoBox: values.postalPOBox,
            personPostalSuburb: values.postalCitySuburb,
            personPostalState: values.postalState,
            personPostalPostCode: values.postalPostalCode,
            personPostalCountry: values.postalCountry,
            isMarketingConsent: values.isMarketingConsent,

            recurringMatterTemplateId: values.recurringMatter?.value,
            matterTypeId: matterTypeId,
            matterTypeState: values.matterTypeState?.label,
            personResponsible: values.personResponsible?.value,
            personActing: values.personActing?.value,
            personAssisting: values.personAssisting?.value,
            personCredit: values.credit?.value,
            matterTitle: values.matterTitle,
            referrerGuid: values.referrer?.value,
            isCreditAgreementRequired: values.costAgreementRequired,
            estimatedFees: values.estimatedFees,
            isEstimatedFeesIncludesTax: values.isEstimatedFeesIncludesTax,
            estimatedDisb: values.estimatedDisbursements,
            isEstimatedDisbIncludeTax: values.isEstimatedDisbursementsIncludesTax,

            gender: values.gender,
            birthDate: birthDate,
            clientRelationshipToEnquirer: props.enquiryDetail.clientRelationshipToEnquirer,

            otherPartyTitle: props.enquiryDetail.otherPartyTitle === 'None' ? '' : props.enquiryDetail.otherPartyTitle,
            otherPartyFirstName: props.enquiryDetail.otherPartyFirstName,
            otherPartyLastName: props.enquiryDetail.otherPartyLastName,
            otherPartyNotes: props.enquiryDetail.otherPartyNotes,
            otherPartyOrganisation: props.enquiryDetail.otherPartyOrganisation,
            otherPartyEmail: props.enquiryDetail.otherPartyEmail,
            otherPartyPhone: props.enquiryDetail.otherPartyPhone,
            otherPartyMobile: props.enquiryDetail.otherPartyMobile,

            otherPartyDOB: otherPartyDob,
            otherPartyAddressName: props.enquiryDetail.otherPartyAddressName,
            otherPartyFloor: props.enquiryDetail.otherPartyFloor,
            otherPartyNumber: props.enquiryDetail.otherPartyNumber,
            otherPartyStreet: props.enquiryDetail.otherPartyStreet,
            otherPartyStreet2: props.enquiryDetail.otherPartyStreet2,
            otherPartySuburb: props.enquiryDetail.otherPartySuburb,
            otherPartyState: props.enquiryDetail.otherPartyState,
            otherPartyPostcode: props.enquiryDetail.otherPartyPostcode,
            otherPartyCountry: props.enquiryDetail.otherPartyCountry,

            otherPartyPOBoxType: props.enquiryDetail.otherPartyPOBoxType,
            otherPartyPOBox: props.enquiryDetail.otherPartyPOBox,
            otherPartyPostalSuburb: props.enquiryDetail.otherPartyPostalSuburb,
            otherPartyPostalState: props.enquiryDetail.otherPartyPostalState,
            otherPartyPostalPostCode: props.enquiryDetail.otherPartyPostalPostCode,
            otherPartyPostalCountry: props.enquiryDetail.otherPartyPostalCountry,
            otherPartyNameGuid: props.enquiryDetail.otherPartyNameGuid,
            otherPartyCompanyGuid: props.enquiryDetail.otherPartyOrganisationGuid,

            historyFile: {
                file: historyDocument,
                name: 'RedView CRM Enquiry History.pdf', // if this is renamed, make sure to change a condition in the mutation as well.
                guidID: null,
                status: 1, // new file.
            },

            // secondPerson
            secondClientPhone: values.secondPersonPhone,
            secondClientEmail: values.secondPersonEmail,
            secondClientTitle:  values.secondPersonTitle === 'None' ? '' : values.secondPersonTitle,
            secondClientMobile: values.secondPersonMobile,
            secondClientFirstName: values.secondPersonFirstName ? values.secondPersonFirstName : null,
            secondClientLastName: values.secondPersonLastName ? values.secondPersonLastName : null,
            secondClientGuid: props.enquiryDetail.secondClientGuid,
            secondClientRelationshipToEnquirer: props.enquiryDetail.secondClientRelationshipToEnquirer,
            secondClientDOB: secondClientDobFinal,
            secondClientGender: values.secondPersonGender ?? null,
            secondClientIsMarketingConsent: values.secondPersonIsMarketingConsent,
            secondClientIsTermsAndConditionsApproved: props.enquiryDetail.secondClientIsTermsAndConditionsApproved,
            secondClientIsPrivacyStatementProvided: props.enquiryDetail.secondClientIsPrivacyStatementProvided,
            secondClientIsConflictCheckPerformed: props.enquiryDetail.secondClientIsConflictCheckPerformed,
            secondClientIsIdentityCheckPerformed: props.enquiryDetail.secondClientIsIdentityCheckPerformed,
            secondClientIsNoConflict: props.enquiryDetail.secondClientIsNoConflict,
            secondClientAddressName: values.secondPersonAddress ?? null,
            secondClientFloor: values.secondPersonUnitLevel ?? null,
            secondClientNumber: values.secondPersonNumber ?? null,
            secondClientStreet1: values.secondPersonStreet1 ?? null,
            secondClientStreet2: values.secondPersonStreet2 ?? null,
            secondClientSuburb: values.secondPersonCitySuburb ?? null,
            secondClientState: values.secondPersonState ?? null,
            secondClientPostCode: values.secondPersonPostalCode ?? null,
            secondClientCountry: values.secondPersonCountry ?? null,
            secondClientPOBoxType: values.secondPersonPostalPoBoxType ?? null,
            secondClientPOBox: values.secondPersonPostalPOBox ?? null,
            secondClientPostalSuburb: values.secondPersonPostalCitySuburb ?? null,
            secondClientPostalState: values.secondPersonPostalState ?? null,
            secondClientPostalPostCode: values.secondPersonPostalPostalCode ?? null,
            secondClientPostalCountry: values.secondPersonPostalCountry ?? null,

            isNameFromDataSource: values.isNameFromDataSource ?? props.enquiryDetail.isNameFromDataSource ?? null,
            isOrganisationFromDataSource: values.isOrganisationFromDataSource ?? props.enquiryDetail.isOrganisationFromDataSource ?? null,
            isOtherSideFromDataSource: values.isOtherSideFromDataSource ?? props.enquiryDetail.isOtherSideFromDataSource ?? null,
            isSecondClientFromDataSource: values.isSecondClientFromDataSource ?? props.enquiryDetail.isSecondClientFromDataSource ?? null
        };
    };

    const getAddressLabelForPanel = (
        buildingName: string,
        level: string,
        streetNumber: string,
        street1: string,
        street2: string,
        suburb: string,
        addressState: string,
        postCode: string,
        country: string,
    ) => {
        let address = buildingName ? buildingName : '';
        if (level && level.length > 0) {
            address = address.length > 0 ? address + ', ' + level : level;
        }
        if (streetNumber && streetNumber.length > 0) {
            address = address.length > 0 ? address + (level && level.length > 0 ? '/' : ' ') + streetNumber : streetNumber;
        }
        if (street1 && street1.length > 0) {
            address = address.length > 0 ? address + ' ' + street1 : street1;
        }
        if (street2 && street2.length > 0) {
            address = address.length > 0 ? address + ', ' + street2 : street2;
        }
        if (suburb && suburb.length > 0) {
            address = address.length > 0 ? address + ', ' + suburb : suburb;
        }
        if (addressState && addressState.length > 0) {
            address = address.length > 0 ? address + ', ' + addressState : addressState;
        }
        if (postCode && postCode.length > 0) {
            address = address.length > 0 ? address + ', ' + postCode : postCode;
        }
        if (country && country.length > 0) {
            address = address.length > 0 ? address + ', ' + country : country;
        }

        return address;
    };

    // tslint:disable-next-line: no-any
    const birthDateValidator = (value: any) => {
        if (value) {
            // check for a valid date and check for sql's min date
            var date = moment(value);

            if (date.isValid() && date.year() >= 1753 && date.toDate() <= moment().toDate()) {
                return undefined;
            } else {
                return 'Incorrect Date';
            }
        }

        return undefined;
    };

    const actionButton = (
        // tslint:disable-next-line: no-any
        form: FormApi<any>,
        submitting: boolean,
        pristine: boolean,
        // tslint:disable-next-line: no-any
        values: any,
    ) => {
        return (
            <div className={classes.buttonContainer}>
                <div>
                    <Button 
                        variant="contained" 
                        type="submit" 
                        className={classes.button} 
                        disabled={submitting || state.isLoading} 
                        onClick={() => onClickSave(form, values)}
                    >
                        Save
                    </Button>
                </div>
                <div>
                    <Button id="resetButton" type="button" onClick={() => onReset(form, values)} disabled={submitting || pristine} className={classes.hidden}>
                        Reset
                    </Button>
                    <Button variant="contained" type="button" className={classes.button} onClick={() => closePanel(form, values)}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    };

    const disabledAddress = (values: AnyObject) => {
        if (!state.isSwitched && values.organisation !== null) {
            return true;
        } else if (state.isSwitched && (values.organisationText === '' || values.organisationText === undefined)) {
            return true;
        } else if (!state.isSwitched && values.organisation === null) {
            return false;
        }
        return false;
    };

    const disabledPersonAddress = (values: AnyObject) => {
        if (state.isSwitched && (values.organisationText === '' || values.organisationText === undefined)) {
            return false;
        } else if (!state.isSwitched && values.organisation === null) {
            return false;
        }
        return true;
    };

    // tslint:disable-next-line: max-line-length no-any
    const onCheckboxChanged = (input: FieldInputProps<CreateClientForm, any>, form: FormApi<CreateClientForm>, values: AnyObject) => (event: React.ChangeEvent<{}>, checked: boolean) => {
        input.onChange(checked);

        form.setConfig('keepDirtyOnReinitialize', false);
        form.batch(() => {
            form.change('recurringMatter', null);
            form.change('matterCategory', null);
            form.change('matterTypeState', null);
            form.change('matterType', null);
        });
        form.setConfig('keepDirtyOnReinitialize', true);
    };

    // tslint:disable-next-line: no-any
    const onSelectionChanged = (selection: any, name: string, input: FieldInputProps<CreateClientForm, any>, form: FormApi<CreateClientForm>, values: AnyObject) => {

        form.setConfig('keepDirtyOnReinitialize', false);

        form.batch(() => {
            if (name === 'recurringMatter') {
                if (selection) {
                    input.onChange(selection);

                    if (selection.hasOwnProperty('matterTypeId') && selection.hasOwnProperty('matterTypeName')) {
                        const matterTypeValue = { label: selection.matterTypeName, value: selection.matterTypeId };
                        form.change('matterType', matterTypeValue);
                        form.change('matterTitle', selection.matterTypeName);
                    } else {
                        form.change('matterType', null);
                        form.change('matterTitle', null);
                    }

                    if (selection.hasOwnProperty('state') && selection.state) {
                        const matterTypeState = { label: selection.state, value: selection.state };
                        form.change('matterTypeState', matterTypeState);
                    } else {
                        form.change('matterTypeState', null);
                    }

                    if (selection.hasOwnProperty('matterTitle') && selection.matterTitle) {
                        form.change('matterTitle', selection.matterTitle);
                    } else {
                        form.change('matterTitle', null);
                    }

                    if (selection.hasOwnProperty('referrerGuid') && selection.referrerGuid) {

                        if (props.enquiryDetail && props.enquiryDetail.referrerName && props.enquiryDetail.referrerGuid) {
                            const tempReferrer = { label: props.enquiryDetail.referrerName, value: props.enquiryDetail.referrerGuid };
                            form.change('referrer', tempReferrer);
                        } else {
                            const referrerValue = { label: selection.referrerName, value: selection.referrerGuid };
                            form.change('referrer', referrerValue);
                        }
                    } else {
                        if (props.enquiryDetail && props.enquiryDetail.referrerName && props.enquiryDetail.referrerGuid) {
                            const tempReferrer = { label: props.enquiryDetail.referrerName, value: props.enquiryDetail.referrerGuid };
                            form.change('referrer', tempReferrer);
                        } else {
                            form.change('referrer', null);
                        }
                    }

                    if (selection.hasOwnProperty('reportingGroupGuid') && selection.reportingGroupGuid) {
                        const reportingGroup = { label: selection.reportingGroup, value: selection.reportingGroupGuid };
                        form.change('reportingGroup', reportingGroup);

                    } else {
                        form.change('reportingGroup', null);
                    }

                    if (selection.hasOwnProperty('workObtained') && selection.workObtained) {
                        // const workObtained = { label: selection.workObtained, value: selection.workObtained };
                        form.change('workObtained', selection.workObtained);

                    } else {
                        form.change('workObtained', null);
                    }

                    if (selection.hasOwnProperty('responsibleGuid') && selection.responsibleGuid) {
                        const responsibleValue = { label: selection.responsibleName, value: selection.responsibleGuid };
                        form.change('personResponsible', responsibleValue);
                    } else {
                        form.change('personResponsible', null);
                    }

                    if (selection.hasOwnProperty('actingGuid') && selection.actingGuid) {
                        const actingValue = { label: selection.actingName, value: selection.actingGuid };
                        form.change('personActing', actingValue);
                    } else {
                        form.change('personActing', null);
                    }

                    if (selection.hasOwnProperty('assistingGuid') && selection.actingGuid) {
                        const assistingValue = { label: selection.assistingName, value: selection.assistingGuid };
                        form.change('personAssisting', assistingValue);
                    } else {
                        form.change('personAssisting', null);
                    }

                    if (selection.hasOwnProperty('creditGuid') && selection.creditGuid) {
                        const creditValue = { label: selection.creditName, value: selection.creditGuid };
                        form.change('credit', creditValue);
                    } else {
                        form.change('credit', null);
                    }

                    if (selection.hasOwnProperty('costAgreementRequired') && selection.costAgreementRequired) {
                        form.change('costAgreementRequired', selection.costAgreementRequired);
                    } else {
                        form.change('costAgreementRequired', false);
                    }

                    // These values are passed in from enquiry as props.
                    // Estimated Fees
                    if (selection.hasOwnProperty('costsEstimate') && selection.costsEstimate) {
                        form.change('estimatedFees', props.enquiryDetail && props.enquiryDetail.estimatedFees ? props.enquiryDetail.estimatedFees : selection.costsEstimate);
                    } else {
                        form.change('estimatedFees', props.enquiryDetail && props.enquiryDetail.estimatedFees ? props.enquiryDetail.estimatedFees : null);
                    }

                    if (selection.hasOwnProperty('isEstimatedFeesIncludesTax') && selection.isEstimatedFeesIncludesTax) {
                        form.change(
                            'isEstimatedFeesIncludesTax',
                            props.enquiryDetail && props.enquiryDetail.isEstimatedFeesIncludesTax
                                ? props.enquiryDetail.isEstimatedFeesIncludesTax
                                : selection.isEstimatedFeesIncludesTax,
                        );
                    } else {
                        form.change(
                            'isEstimatedFeesIncludesTax',
                            props.enquiryDetail && props.enquiryDetail.isEstimatedFeesIncludesTax ? props.enquiryDetail.isEstimatedFeesIncludesTax : false,
                        );
                    }

                    // Disbursement Estimates
                    if (selection.hasOwnProperty('disbursementsEstimate') && selection.disbursementsEstimate) {
                        form.change(
                            'estimatedDisbursements',
                            props.enquiryDetail && props.enquiryDetail.estimatedDisbursements ? props.enquiryDetail.estimatedDisbursements : selection.disbursementsEstimate,
                        );
                    } else {
                        form.change(
                            'estimatedDisbursements',
                            props.enquiryDetail && props.enquiryDetail.estimatedDisbursements ? props.enquiryDetail.estimatedDisbursements : null,
                        );
                    }

                    if (selection.hasOwnProperty('isEstimatedDisbursementsIncludesTax') && selection.isEstimatedDisbursementsIncludesTax) {
                        form.change(
                            'isEstimatedDisbursementsIncludesTax',
                            props.enquiryDetail && props.enquiryDetail.isEstimatedDisbursementsIncludesTax
                                ? props.enquiryDetail.isEstimatedDisbursementsIncludesTax
                                : selection.isEstimatedDisbursementsIncludesTax,
                        );
                    } else {
                        form.change(
                            'isEstimatedDisbursementsIncludesTax',
                            props.enquiryDetail && props.enquiryDetail.isEstimatedDisbursementsIncludesTax ? props.enquiryDetail.isEstimatedDisbursementsIncludesTax : false,
                        );
                    }
                } else {
                    input.onChange(null);
                }
            }

            if (name === 'matterCategory') {
                if (selection) {
                    input.onChange(selection);
                } else {
                    input.onChange(null);
                }
                form.change('matterTypeState', null);
                form.change('matterType', null);
                form.change('matterTitle', null);
            }

            if (name === 'matterTypeState') {
                if (selection) {
                    input.onChange(selection);
                    form.change('matterTitle', selection.matterTypeName);
                } else {
                    input.onChange(null);
                    form.change('matterTitle', null);
                }

                form.change('matterType', null);
                form.change('matterTypeTree', null);
            }

            if (name === 'matterType') {
                if (selection) {
                    input.onChange(selection);
                    form.change('matterTitle', selection.label);
                } else {
                    input.onChange(null);
                    form.change('matterTitle', null);
                }
            }

            if (name === 'matterTypeTree') {
                if (selection) {
                    input.onChange(selection);
                    form.change('matterTitle', selection.label);
                } else {
                    input.onChange(null);
                }
            }
        });

        form.setConfig('keepDirtyOnReinitialize', true);
    };

    const openErrorPanel = (errors: ValidationErrors) => {
        if (Object.keys(errors).length) {
            setState((prevState) => {
                let isOrganisationExpandPanel = false;
                let isOrgnisationPostralAddressExpandPanel = false;
                let isPersonExpandPanel = false;
                let isPersonPostalAddressExpandPanel = false;
                let isCreateMatterExpandPanel = false;
                let isPerson2ExpandPanel = false;
                let isPerson2PostalAddressExpandPanel = false;

                if (errors.hasOwnProperty('orgPostalPOBox')) {
                    isOrganisationExpandPanel = true;
                    isOrgnisationPostralAddressExpandPanel = true;
                }

                if (prevState.isOrganisationExpandPanel) {
                    isOrganisationExpandPanel = true;
                }

                if (prevState.isOrgnisationPostralAddressExpandPanel) {
                    isOrgnisationPostralAddressExpandPanel = true;
                }

                // person
                if (
                    errors.hasOwnProperty('postalPOBox') ||
                    errors.hasOwnProperty('title') ||
                    errors.hasOwnProperty('firstName') ||
                    errors.hasOwnProperty('lastName') /*|| errors.hasOwnProperty('email')*/
                ) {
                    isPersonExpandPanel = true;
                    isPersonPostalAddressExpandPanel = true;
                }

                if (prevState.isPersonExpandPanel) {
                    isPersonExpandPanel = true;
                }

                if (errors.hasOwnProperty('postalPOBox')) {
                    isPersonPostalAddressExpandPanel = true;
                }

                if (prevState.isPersonPostalAddressExpandPanel) {
                    isPersonPostalAddressExpandPanel = true;
                }

                // person 2
                if (
                    errors.hasOwnProperty('secondPersonPostalPOBox') ||
                    errors.hasOwnProperty('secondPersonTitle') ||
                    errors.hasOwnProperty('secondPersonFirstName') ||
                    errors.hasOwnProperty('secondPersonLastName') /*|| errors.hasOwnProperty('email')*/
                ) {
                    isPerson2ExpandPanel = true;
                    isPerson2PostalAddressExpandPanel = true;
                }

                if (errors.hasOwnProperty('secondPersonPostalPOBox')) {
                    isPerson2PostalAddressExpandPanel = true;
                }

                if (prevState.isPerson2ExpandPanel) {
                    isPerson2ExpandPanel = true;
                }
                
                if (
                    errors.hasOwnProperty('recurringMatter') ||
                    errors.hasOwnProperty('matterCategory') ||
                    errors.hasOwnProperty('matterTypeState') ||
                    errors.hasOwnProperty('matterType') ||
                    errors.hasOwnProperty('personResponsible') ||
                    errors.hasOwnProperty('personActing') ||
                    errors.hasOwnProperty('matterTitle') || 
                    errors.hasOwnProperty('matterTypeTree')
                ) {
                    isCreateMatterExpandPanel = true;
                }

                if (prevState.isCreateMatterExpandPanel) {
                    isCreateMatterExpandPanel = true;
                }

                return {
                    ...prevState,
                    isOrganisationExpandPanel: isOrganisationExpandPanel,
                    isOrgnisationPostralAddressExpandPanel: isOrgnisationPostralAddressExpandPanel,
                    isPersonExpandPanel: isPersonExpandPanel,
                    isPersonPostalAddressExpandPanel: isPersonPostalAddressExpandPanel,
                    isCreateMatterExpandPanel: isCreateMatterExpandPanel,
                    isPerson2ExpandPanel: isPerson2ExpandPanel,
                    isPerson2PostalAddressExpandPanel: isPerson2PostalAddressExpandPanel,
                    isFormSubmit: false,
                };
            });
        }
    };

    const onExpandPanel = (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
        setState((prevState) => {
            return {
                ...prevState,
                [panel]: expanded,
            };
        });
    };

    // const createMatterByTenant = (form: FormApi<CreateClientForm>, submitting: boolean, pristine: boolean, values: AnyObject) => {

    //     const tenant = tenantQuery.data?.tenant.tenantId;

    //     if (tenant === '0010' || tenant === '0099') {
    //         return (
    //             <>
    //                 <Grid item={true} xs={6}>
    //                     <Field
    //                         name="matterTypeState"
    //                         subscription={{ touched: true, error: true, value: true }}
    //                         allowNull={true}
    //                         validate={canCreateMatter ? required : undefined}
    //                     >
    //                         {({ input, meta }) => (
    //                             <MatterTypeStateSelector
    //                                 {...input}
    //                                 label="State"
    //                                 required={canCreateMatter}
    //                                 error={meta.error && meta.touched}
    //                                 helperText={meta.error && meta.touched ? 'Field is required' : ''}
    //                                 onSelection={(selection: IAutoCompleteItem, name: string) => onSelectionChanged(selection, name, input, form, values)}
    //                                 onBlur={input.onBlur}
    //                                 // disabled={values.matterCategory ? false : true}
    //                             />
    //                         )}
    //                     </Field>
    //                 </Grid>
    //                 <Grid item={true} xs={6}>
    //                     <Field
    //                         name="matterTypeTree"
    //                         subscription={{ touched: true, error: true, value: true }}
    //                         allowNull={true}
    //                         validate={canCreateMatter ? required : undefined}
    //                     >
    //                         {({ input, meta }) => (
    //                             <MatterTreeTypeSelector
    //                                 {...input}
    //                                 label="Type"
    //                                 error={meta.error && meta.touched && values.matterTypeState}
    //                                 required={canCreateMatter}
    //                                 state={values.matterTypeState ? values.matterTypeState.label : ''}
    //                                 helperText={values.matterTypeState && meta.error && meta.touched ? 'Field is required' : ''}
    //                                 onSelection={(selection: TreeSelectNode | null, name: string) => onSelectionChanged(selection, name, input, form, values)}
    //                                 disabled={values.matterTypeState ? false : true}
    //                             />
    //                         )}
    //                     </Field>
    //                 </Grid>
    //             </>
    //         );
    //     }

    //     return (
    //         <>
    //             <Grid item={true} xs={5}>
    //                 <Field
    //                     name="matterCategory"
    //                     subscription={{ touched: true, error: true, value: true }}
    //                     allowNull={true}
    //                     validate={canCreateMatter ? required : undefined}
    //                 >
    //                     {({ input, meta }) => (
    //                         <MatterCategorySelector
    //                             {...input}
    //                             label="Category"
    //                             error={meta.error && meta.touched}
    //                             required={canCreateMatter}
    //                             helperText={meta.error && meta.touched ? 'Field is required' : ''}
    //                             onSelection={(selection: IAutoCompleteItem, name: string) => onSelectionChanged(selection, name, input, form, values)}
    //                             onBlur={input.onBlur}
    //                         />
    //                     )}
    //                 </Field>
    //             </Grid>
    //             <Grid item={true} xs={3}>
    //                 <Field
    //                     name="matterTypeState"
    //                     subscription={{ touched: true, error: true, value: true }}
    //                     allowNull={true}
    //                     validate={canCreateMatter ? required : undefined}
    //                 >
    //                     {({ input, meta }) => (
    //                         <MatterTypeStateSelector
    //                             {...input}
    //                             label="State"
    //                             required={canCreateMatter}
    //                             error={meta.error && meta.touched}
    //                             helperText={meta.error && meta.touched ? 'Field is required' : ''}
    //                             onSelection={(selection: IAutoCompleteItem, name: string) => onSelectionChanged(selection, name, input, form, values)}
    //                             onBlur={input.onBlur}
    //                             disabled={values.matterCategory ? false : true}
    //                         />
    //                     )}
    //                 </Field>
    //             </Grid>
    //             <Grid item={true} xs={4}>
    //                 <Field
    //                     name="matterType"
    //                     subscription={{ touched: true, error: true, value: true }}
    //                     allowNull={true}
    //                     validate={canCreateMatter ? required : undefined}
    //                 >
    //                     {({ input, meta }) => (
    //                         <MatterTypeSelector
    //                             {...input}
    //                             label="Type"
    //                             error={meta.error && meta.touched}
    //                             helperText={meta.error && meta.touched ? 'Field is required' : ''}
    //                             onSelection={(selection: IAutoCompleteItem, name: string) => onSelectionChanged(selection, name, input, form, values)}
    //                             onBlur={input.onBlur}
    //                             disabled={values.matterTypeState ? false : true}
    //                             filter={{
    //                                 state: values.matterTypeState ? values.matterTypeState.label : undefined,
    //                                 category: values.matterCategory ? values.matterCategory.value : undefined,
    //                             }}
    //                             required={canCreateMatter}
    //                         />
    //                     )}
    //                 </Field>
    //             </Grid>
    //         </>
    //     );
    // };

    const createMatterExpandablePanel = (form: FormApi<CreateClientForm>, submitting: boolean, pristine: boolean, values: AnyObject) => {
        return (
            <Accordion defaultExpanded={true} expanded={state.isCreateMatterExpandPanel} onChange={onExpandPanel('isCreateMatterExpandPanel')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                    <Typography className={classes.heading}>
                        <SubjectIcon className={classes.headingIcon} />
                        Create Matter
                    </Typography>
                    <div className={classes.infoContainer}>
                        <div className="col">
                            <Field name="useRecurringMatter" type="checkbox" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        // className={classes.formControlLabel}
                                        {...input}
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                        onChange={onCheckboxChanged(input, form, values)}
                                        control={<Checkbox checkedIcon={<CheckBoxIcon color="primary" fontSize="small" />} />}
                                        label="Use Recurring Matter"
                                    />
                                )}
                            </Field>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.expandablePanelDetails}>
                    <Grid container={true} spacing={3}>
                        {values.useRecurringMatter ? (
                            <Grid item={true} xs={12}>
                                <Field
                                    name="recurringMatter"
                                    subscription={{ touched: true, error: true, value: true }}
                                    allowNull={true}
                                    validate={canCreateMatter ? required : undefined}
                                >
                                    {({ input, meta }) => (
                                        <RecurringMatterSelector
                                            {...input}
                                            label="Recurring Matter"
                                            onSelection={(selection: RecurringMatterItem, name: string) => onSelectionChanged(selection, name, input, form, values)}
                                            error={meta.error && meta.touched}
                                            helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                            onBlur={input.onBlur}
                                            required={canCreateMatter}
                                        />
                                    )}
                                </Field>
                            </Grid>
                        ) : (
                            // createMatterByTenant(form, submitting, pristine, values)
                            <>
                                <Grid item={true} xs={6}>
                                    <Field
                                        name="matterTypeState"
                                        subscription={{ touched: true, error: true, value: true }}
                                        allowNull={true}
                                        validate={canCreateMatter ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <MatterTypeStateSelector
                                                {...input}
                                                label="State"
                                                required={canCreateMatter}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                                onSelection={(selection: IAutoCompleteItem, name: string) => onSelectionChanged(selection, name, input, form, values)}
                                                onBlur={input.onBlur}
                                                // disabled={values.matterCategory ? false : true}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={6}>
                                    <Field
                                        name="matterTypeTree"
                                        subscription={{ touched: true, error: true, value: true }}
                                        allowNull={true}
                                        validate={canCreateMatter ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <MatterTreeTypeSelector
                                                {...input}
                                                label="Type"
                                                error={meta.error && meta.touched && values.matterTypeState}
                                                required={canCreateMatter}
                                                state={values.matterTypeState ? values.matterTypeState.label : ''}
                                                helperText={values.matterTypeState && meta.error && meta.touched ? 'Field is required' : ''}
                                                onSelection={(selection: TreeSelectNode | null, name: string) => onSelectionChanged(selection, name, input, form, values)}
                                                disabled={values.matterTypeState ? false : true}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </>
                        )}
                        <Grid item={true} xs={6}>
                            <Field
                                name="personResponsible"
                                subscription={{ touched: true, error: true, value: true }}
                                allowNull={true}
                                validate={canCreateMatter ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <StaffSelector
                                        {...input}
                                        label={localStorage.getItem(LocalStorageEnum.PersonResponsible) || 'Person Responsible'}
                                        disablePortal={false}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                        onBlur={input.onBlur}
                                        required={canCreateMatter}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Field
                                name="personActing"
                                subscription={{ touched: true, error: true, value: true }}
                                allowNull={true}
                                validate={canCreateMatter ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <StaffSelector
                                        {...input}
                                        label={localStorage.getItem(LocalStorageEnum.PersonActing) || 'Person Acting'}
                                        disablePortal={false}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                        onBlur={input.onBlur}
                                        required={canCreateMatter}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Field name="personAssisting" subscription={{ touched: true, error: true, value: true }} allowNull={true}>
                                {({ input, meta }) => (
                                    <StaffSelector
                                        {...input}
                                        label="Person Assisting"
                                        disablePortal={false}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                        onBlur={input.onBlur}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Field name="credit" subscription={{ touched: true, error: true, value: true }} allowNull={true}>
                                {({ input, meta }) => (
                                    <StaffSelector
                                        {...input}
                                        label="Credit"
                                        disablePortal={false}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection)}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                        onBlur={input.onBlur}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Field
                                name="matterTitle"
                                subscription={{ touched: true, error: true, value: true }}
                                // allowNull={true}
                                validate={canCreateMatter ? required : undefined}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Matter Title"
                                        type="text"
                                        required={canCreateMatter}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        InputLabelProps={{
                                            shrink: input.value ? true : false,
                                        }}
                                        helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Field name="referrer" subscription={{ touched: true, error: true, value: true }} allowNull={true}>
                                {({ input, meta }) => (
                                    <ClientAndContactSelector
                                        {...input}
                                        label="Referrer"
                                        required={false}
                                        name="referrer"
                                        onSelection={(selection: IAutoCompleteItem, name: string) => {
                                            input.onChange(selection);
                                        }}
                                        onBlur={input.onBlur}
                                        includeNonPmsContacts={false}
                                        includePmsContacts={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                        {/* <Grid item={true} xs={6}>
                            <Field name="workObtained" subscription={{ touched: true, error: true, value: true }} allowNull={true}>
                                {({ input, meta }) => (
                                    <WorkObtainedSelector
                                        {...input}
                                        label="Work Obtained"
                                        name="workObtained"
                                        required={false}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => {
                                            input.onChange(selection);
                                        }}
                                        onBlur={input.onBlur}
                                    />
                                )}
                            </Field>
                        </Grid> */}
                        <Grid item={true} xs={6}>
                            <Field name="reportingGroup" subscription={{ touched: true, error: true, value: true }} allowNull={true}>
                                {({ input, meta }) => (
                                    <ReportingGroupSelector
                                        {...input}
                                        label="Reporting Group"
                                        name="reportingGroup"
                                        required={false}
                                        onSelection={(selection: IAutoCompleteItem, name: string) => {
                                            input.onChange(selection);
                                        }}
                                        onBlur={input.onBlur}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <div className={classes.fieldAndCheckbox}>
                                <Field
                                    name="estimatedFees"
                                    label="Estimated Fees"
                                    // helperText={'Debtors amount to write off'}
                                    subscription={{ touched: true, error: true, value: true }}
                                    prefix={state.currency}
                                    // className={classes.textField}
                                    component={CurrencyInput}
                                    allowNull={true}
                                    // disabled={props.formAction === 'edit'}
                                />
                                <Field name="isEstimatedFeesIncludesTax" type="checkbox" subscription={{ touched: true, error: true, value: true }}>
                                    {({ input, meta }) => (
                                        <FormControlLabel
                                            className={classes.formControlLabel}
                                            control={<Checkbox {...input} checkedIcon={<CheckBoxIcon color="primary" />} />}
                                            label="Includes Tax"
                                        />
                                    )}
                                </Field>
                            </div>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.customFieldGrid}>
                            <div className={classes.fieldAndCheckbox}>
                                <Field
                                    name="estimatedDisbursements"
                                    label="Estimated Disbs"
                                    // helperText={'Debtors amount to write off'}
                                    subscription={{ touched: true, error: true, value: true }}
                                    prefix={state.currency}
                                    // className={classes.textField}
                                    component={CurrencyInput}
                                    allowNull={true}
                                    // disabled={props.formAction === 'edit'}
                                />
                                <Field name="isEstimatedDisbursementsIncludesTax" type="checkbox" subscription={{ touched: true, error: true, value: true }}>
                                    {({ input, meta }) => (
                                        <FormControlLabel
                                            className={classes.formControlLabel}
                                            control={<Checkbox {...input} checkedIcon={<CheckBoxIcon color="primary" />} />}
                                            label="Includes Tax"
                                        />
                                    )}
                                </Field>
                            </div>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Field name="costAgreementRequired" type="checkbox" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        // className={classes.formControlLabel}
                                        control={<Checkbox {...input} onChange={input.onChange} checkedIcon={<CheckBoxIcon color="primary" />} />}
                                        label="Cost Agreement Required"
                                    />
                                )}
                            </Field>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    // tslint:disable-next-line
    const organisationExpandablePanel = (form: FormApi<CreateClientForm>, submitting: boolean, pristine: boolean, values: AnyObject) => {
        // let defaultExpanded = props.organisation.length > 0 ? true : false;

        return (
            <Accordion
                // defaultExpanded={defaultExpanded}
                expanded={state.isOrganisationExpandPanel}
                onChange={onExpandPanel('isOrganisationExpandPanel')}
                disabled={state.isOrganisationPanelDisabled}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                    <Typography className={classes.heading}>
                        <BusinessIcon className={classes.headingIcon} />
                        Organisation
                    </Typography>

                    <div className={classes.infoContainer}>
                        <div className="col">
                            <div className="value">{`${values.organisation && values.organisation.label ? values.organisation.label : ''}`}</div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.expandablePanelDetails}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12}>
                            <div className={classes.organisationFieldWrapepr}>
                                <div className="field">
                                    {!state.isSwitched ? (
                                        <Field name="organisation" subscription={{ touched: true, error: true, value: true }}>
                                            {({ input, meta }) => (
                                                <OrganisationSelector
                                                    {...input}
                                                    label="Organisation"
                                                    error={meta.error && meta.touched}
                                                    helperText={meta.error && meta.touched ? 'Organisation is required' : ''}
                                                    onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                        // Another approach.
                                                        onDropDownSelect(form, selection, name);
                                                    }}
                                                    disabled={state.isOrganisationPanelDisabled}
                                                />
                                            )}
                                        </Field>
                                    ) : (
                                        <Field
                                            name="organisationText"
                                            subscription={{ touched: true, error: true, value: true }}
                                            // validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    {...input}
                                                    label="Organisation"
                                                    type="text"
                                                    className={classes.textField}
                                                    error={meta.error && meta.touched}
                                                    helperText={meta.error && meta.touched ? 'Organisation is required' : ''}
                                                    disabled={state.isOrganisationPanelDisabled}
                                                />
                                            )}
                                        </Field>
                                    )}
                                </div>
                                <div className="switcher">
                                    <Tooltip
                                        // placement="left-start"
                                        title={
                                            state.isSwitched ? (
                                                <Typography color="inherit">Switch to select an existing organisation</Typography>
                                            ) : (
                                                <Typography color="inherit">Switch to enter a new organisation name</Typography>
                                            )
                                        }
                                    >
                                        <IconButton aria-label="delete" className={classes.margin} onClick={onSwitch} disabled={state.isOrganisationPanelDisabled}>
                                            <SyncAltIcon fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </div>
                        </Grid>
                        <Grid item={true} md={12} xs={12}>
                            {orgAddressPanel(true, form)}
                        </Grid>
                        <Grid item={true} md={12} xs={12}>
                            {orgPostalAddressPanel(false, form)}
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    // tslint:disable-next-line
    const orgAddressPanel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion defaultExpanded={defaultExpanded} disabled={disabledAddress(values)}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                            <Typography className={classes.heading}>
                                <ContactMailIcon className={classes.headingIcon} />
                                Main Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {getAddressLabelForPanel(
                                            values.orgBuildingName,
                                            values.orgUnitLevel,
                                            values.orgNumber,
                                            values.orgStreet1,
                                            values.orgStreet2,
                                            values.orgCitySuburb,
                                            values.orgState,
                                            values.orgPostalCode,
                                            values.orgCountry,
                                        )}

                                        {/* {values.orgBuildingName ? `${values.orgBuildingName}, ` : ''}
                                        {values.orgUnitLevel ? `${values.orgUnitLevel}, ` : ''}
                                        {values.orgNumber ? `${values.orgNumber}, ` : ''}
                                        {values.orgStreet1 ? `${values.orgStreet1}, ` : ''}
                                        {values.orgCitySuburb ? `${values.orgCitySuburb}, ` : ''}
                                        {values.orgState ? `${values.orgState}, ` : ''}
                                        {values.orgPostalCode ? `${values.orgPostalCode}, ` : ''}
                                        {values.orgCountry ? `${values.orgCountry}` : ''} */}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.expandablePanelDetails}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} md={6} xs={12}>
                                    <Field name="orgBuildingName" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Building name"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Building name is required' : ''}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Field name="orgUnitLevel" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Unit/Level"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Unit/Level is required' : ''}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Field name="orgNumber" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Number is required' : ''}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field name="orgStreet1" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 1"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                name="orgStreet1"
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={meta.error && meta.touched ? 'Street 1 is required' : ''}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'orgStreet1')}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <Field
                                        name="orgStreet2"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Street 2"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                name="orgStreet2"
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={meta.error && meta.touched ? 'Street 2 is required' : ''}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'orgStreet2')}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="orgCitySuburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="City/Suburb"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'City/Suburb is required' : ''}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="orgState"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'State is required' : ''}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="orgPostalCode"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Postal Code is required' : ''}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Field
                                        name="orgCountry"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Country is required' : ''}
                                                inputProps={{
                                                    autoComplete: 'new-password',
                                                }}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    // tslint:disable-next-line: max-line-length
    const orgPostalAddressPanel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        expanded={state.isOrgnisationPostralAddressExpandPanel}
                        onChange={onExpandPanel('isOrgnisationPostralAddressExpandPanel')}
                        disabled={disabledAddress(values)}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                            <Typography className={classes.heading}>
                                <LocalPostOfficeIcon className={classes.headingIcon} />
                                Postal Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {/* {getAddressLabelForPanel} */}
                                        {values.orgPoBoxType ? `${values.orgPoBoxType} ` : ''}
                                        {values.orgPostalPOBox ? `${values.orgPostalPOBox}, ` : ''}
                                        {values.orgPostalCitySuburb ? `${values.orgPostalCitySuburb}, ` : ''}
                                        {values.orgPostalState ? `${values.orgPostalState}, ` : ''}
                                        {values.orgPostalPostalCode ? `${values.orgPostalPostalCode}, ` : ''}
                                        {values.orgPostalCountry ? `${values.orgPostalCountry}` : ''}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.expandablePanelDetails}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="orgPoBoxType"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <POBoxTypeSelector
                                                {...input}
                                                label="PO Box Type"
                                                // required={true}
                                                value={input.value ? { label: input.value, value: input.value } : null}
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                    input.onChange(selection ? selection.value : null);
                                                }}
                                                helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={8}>
                                    <Field
                                        name="orgPostalPOBox"
                                        subscription={{ touched: true, error: true, value: true }}
                                        validate={isPoBoxRequired(values, true) ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                required={isPoBoxRequired(values, true) ? true : false}
                                                helperText={meta.error && meta.touched ? 'Number is required' : ''}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="orgPostalCitySuburb"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Suburb"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={meta.error && meta.touched ? 'City/Suburb is required' : ''}
                                                placeTypes={['(cities)']}
                                                value={values.street}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'orgPostalCitySuburb')}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="orgPostalState"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'State is required' : ''}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="orgPostalPostalCode"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Postal Code is required' : ''}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Field
                                        name="orgPostalCountry"
                                        subscription={{ touched: true, error: true, value: true }}

                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // disabled={props.sourceSystem?.toLowerCase() === 'leap'}
                                                helperText={meta.error && meta.touched ? 'Country is required' : ''}
                                                disabled={disabledAddress(values)}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    // tslint:disable-next-line
    const address2Panel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion defaultExpanded={defaultExpanded}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                            <Typography className={classes.heading}>
                                <ContactMailIcon className={classes.headingIcon} />
                                Main Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {getAddressLabelForPanel(
                                            values.secondPersonBuildingName,
                                            values.secondPersonUnitLevel,
                                            values.secondPersonNumber,
                                            values.secondPersonStreet1,
                                            values.secondPersonStreet2,
                                            values.secondPersonCitySuburb,
                                            values.secondPersonState,
                                            values.secondPersonPostalCode,
                                            values.secondPersonCountry,
                                        )}
                                        {/* {values.buildingName ? `${values.buildingName}, ` : ''}
                                        {values.unitLevel ? `${values.unitLevel}, ` : ''}
                                        {values.number ? `${values.number}, ` : ''}
                                        {values.street1 ? `${values.street1}, ` : ''}
                                        {values.citySuburb ? `${values.citySuburb}, ` : ''}
                                        {values.state ? `${values.state}, ` : ''}
                                        {values.postalCode ? `${values.postalCode}, ` : ''}
                                        {values.country ? `${values.country}` : ''} */}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.expandablePanelDetails}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} md={6} xs={12}>
                                    <Field
                                        name="secondPersonBuildingName"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Building Name"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Building Name is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Field
                                        name="secondPersonUnitLevel"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Unit/Level"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Unit/Level is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Field
                                        name="secondPersonNumber"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Number is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <FormSpy subscription={{ values: true }}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => (
                                            <Field name="secondPersonStreet1" subscription={{ touched: true, error: true, value: true }}>
                                                {({ input, meta }) => (
                                                    <AddressSelector
                                                        {...input}
                                                        freeSolo={true}
                                                        label="Street 1"
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        name="secondPersonStreet1"
                                                        homeCountry={props.homeCountry}
                                                        // tslint:disable-next-line: max-line-length
                                                        // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                        helperText={meta.error && meta.touched ? 'Street 1 is required' : ''}
                                                        onSelection={onSelection(form, values)}
                                                        onInputChanged={onInputChanged(form, 'secondPersonStreet1')}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                    </FormSpy>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <FormSpy subscription={{ values: true }}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => (
                                            <Field name="secondPersonStreet2" subscription={{ touched: true, error: true, value: true }}>
                                                {({ input, meta }) => (
                                                    <AddressSelector
                                                        {...input}
                                                        freeSolo={true}
                                                        label="Street 2"
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        name="secondPersonStreet2"
                                                        homeCountry={props.homeCountry}
                                                        // tslint:disable-next-line: max-line-length
                                                        // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                        helperText={meta.error && meta.touched ? 'Street 2 is required' : ''}
                                                        onSelection={onSelection(form, values)}
                                                        onInputChanged={onInputChanged(form, 'secondPersonStreet2')}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                    </FormSpy>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="secondPersonCitySuburb" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="City/Suburb"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'City/Suburb is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="secondPersonState" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'State is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="secondPersonPostalCode" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Postal Code is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Field name="secondPersonCountry" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Country is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    // tslint:disable-next-line: max-line-length
    const postalAddress2Panel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        // defaultExpanded={defaultExpanded}
                        expanded={state.isPerson2PostalAddressExpandPanel}
                        onChange={onExpandPanel('isPerson2PostalAddressExpandPanel')}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                            <Typography className={classes.heading}>
                                <LocalPostOfficeIcon className={classes.headingIcon} />
                                Postal Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {values.secondPersonPostalPoBoxType ? `${values.secondPersonPostalPoBoxType} ` : ''}
                                        {values.secondPersonPostalPOBox ? `${values.secondPersonPostalPOBox}, ` : ''}
                                        {values.secondPersonPostalCitySuburb ? `${values.secondPersonPostalCitySuburb}, ` : ''}
                                        {values.secondPersonPostalState ? `${values.secondPersonPostalState}, ` : ''}
                                        {values.secondPersonPostalPostalCode ? `${values.secondPersonPostalPostalCode}, ` : ''}
                                        {values.secondPersonPostalCountry ? `${values.secondPersonPostalCountry}` : ''}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.expandablePanelDetails}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="secondPersonPostalPoBoxType"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <POBoxTypeSelector
                                                {...input}
                                                label="PO Box Type"
                                                // required={true}
                                                value={input.value ? { label: input.value, value: input.value } : null}
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                    input.onChange(selection ? selection.value : null);
                                                }}
                                                helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={8}>
                                    <Field
                                        name="secondPersonPostalPOBox"
                                        subscription={{ touched: true, error: true, value: true }}
                                        validate={isSecondClientPoBoxRequired(values) ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                required={isSecondClientPoBoxRequired(values) ? true : false}
                                                helperText={meta.error && meta.touched ? 'Number is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="secondPersonPostalCitySuburb" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Suburb"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={meta.error && meta.touched ? 'City/Suburb is required' : ''}
                                                placeTypes={['(cities)']}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'secondPersonPostalCitySuburb')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="secondPersonPostalState" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'State is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="secondPersonPostalPostalCode" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Postal Code is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Field name="secondPersonPostalCountry" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // disabled={props.sourceSystem?.toLowerCase() === 'leap'}
                                                helperText={meta.error && meta.touched ? 'Country is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    // tslint:disable-next-line: no-any max-line-length
    const person2ExpandablePanel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>, submitting: boolean, pristine: boolean, values: AnyObject) => {
        return (
            <Accordion
                // defaultExpanded={defaultExpanded}
                expanded={state.isPerson2ExpandPanel}
                onChange={onExpandPanel('isPerson2ExpandPanel')}
                disabled={state.isPerson2PanelDisabled}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                    <Typography className={classes.heading}>
                        <AccessibilityIcon className={classes.headingIcon} />
                        Person 2
                    </Typography>

                    <div className={classes.infoContainer}>
                        <div className="col">
                            <div className="value">
                                {values.secondPersonTitle ? values.secondPersonTitle === 'None' ? '' : `${values.secondPersonTitle} ` : ''}
                                {values.secondPersonFirstName ? `${values.secondPersonFirstName} ` : ''}
                                {values.secondPersonLastName ? `${values.secondPersonLastName} ` : ''}
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.expandablePanelDetails}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="secondPersonTitle" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <>
                                        <TitleAutocompleteSelector
                                            {...input}
                                            label="Person 2 Title"
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            value={input.value ? { label: input.value, value: input.value } : undefined}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection ? selection.value : '');
                                            }}
                                            required={true}
                                            helperText={meta.error && meta.touched ? 'Title is required' : ''}
                                            disabled={state.isPersonPanelDisabled}
                                        />
                                    </>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field 
                                name="secondPersonFirstName" 
                                subscription={{ touched: true, error: true, value: true }} 
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Person 2 First Name"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'First Name is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                        required={values.hasOwnProperty('secondPersonLastName') && values.secondPersonLastName.length > 0 
                                          && !values.secondPersonFirstName ? true : false}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field 
                                name="secondPersonLastName" 
                                subscription={{ touched: true, error: true, value: true }} 
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Person 2 Last Name"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Last Name is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                        required={values.hasOwnProperty('secondPersonFirstName') 
                                          && values.secondPersonFirstName.length > 0 && !values.secondPersonLastName ? true : false}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="secondPersonEmail"
                                subscription={{ touched: true, error: true, value: true }}
                                // validate={EmailValidateWithRequired}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Email"
                                        type="email"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Email is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="secondPersonMobile" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Mobile"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Mobile is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="secondPersonPhone" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Phone"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Phone is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <Field name="secondPersonGeneralNotes" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="General Notes"
                                        type="text"
                                        multiline={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'General Notes is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                            <Field name="secondPersonIsMarketingConsent" type="checkbox" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={<Checkbox {...input} checkedIcon={<CheckBoxIcon color="primary" />} />}
                                        label="Marketing Consent"
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                            <Field
                                name="secondPersonBirthDate"
                                validate={birthDateValidator}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDatePicker
                                        {...input}
                                        value={input && input.value ? input.value : null}
                                        className={classes.textField}
                                        label="Birthdate (of Client)"
                                        format="DD/MM/YYYY"
                                        placeholder="dd/mm/yyyy"
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={false}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? meta.error : ''}
                                        inputProps={{ autocomplete: 'off' }}
                                        InputLabelProps={{ shrink: true }}
                                        maxDate={new Date()}
                                        disabled={state.isPersonPanelDisabled}
                                        onChange={(date: MaterialUiPickersDate | null, value?: string | null) => input.onChange(date)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                            <Field
                                // tslint:disable-next-line: max-line-length
                                name="secondPersonGender"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <GenderSelector
                                        {...input}
                                        label="Gender (of Client)"
                                        name="secondPersonGender"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: max-line-length
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection.value)}
                                        helperText={meta.error && meta.touched ? 'Gender is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <FormSpy subscription={{ values: true }}>
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) => {
                                if (!disabledPersonAddress(values)) {
                                    return (
                                        <>
                                            <Grid item={true} xs={12}>
                                                {address2Panel(true, form)}
                                            </Grid>
                                            <Grid item={true} xs={12}>
                                                {postalAddress2Panel(false, form)}
                                            </Grid>
                                        </>
                                    );
                                }
                                return <div />;
                            }}
                        </FormSpy>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    // tslint:disable-next-line: no-any max-line-length
    const personExpandablePanel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>, submitting: boolean, pristine: boolean, values: any) => {
        return (
            <Accordion
                // defaultExpanded={defaultExpanded}
                expanded={state.isPersonExpandPanel}
                onChange={onExpandPanel('isPersonExpandPanel')}
                disabled={state.isPersonPanelDisabled}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                    <Typography className={classes.heading}>
                        <AccessibilityIcon className={classes.headingIcon} />
                        Person
                    </Typography>

                    <div className={classes.infoContainer}>
                        <div className="col">
                            <div className="value">
                                {values.title ? values.title === 'None' ? '' : `${values.title} ` : ''}
                                {values.firstName ? `${values.firstName} ` : ''}
                                {values.lastName ? `${values.lastName} ` : ''}
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.expandablePanelDetails}>
                    <Grid container={true} spacing={3}>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="title" validate={required} subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <>
                                        <TitleAutocompleteSelector
                                            {...input}
                                            label="Title"
                                            className={classes.textField}
                                            error={meta.error && meta.touched}
                                            value={input.value ? { label: input.value, value: input.value } : undefined}
                                            // tslint:disable-next-line: max-line-length
                                            onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                input.onChange(selection ? selection.value : '');
                                            }}
                                            required={true}
                                            helperText={meta.error && meta.touched ? 'Title is required' : ''}
                                            disabled={state.isPersonPanelDisabled}
                                        />
                                    </>
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="firstName" subscription={{ touched: true, error: true, value: true }} validate={required}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="First Name"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'First Name is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                        required={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="lastName" subscription={{ touched: true, error: true, value: true }} validate={required}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Last Name"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Last Name is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                        required={true}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field
                                name="email"
                                subscription={{ touched: true, error: true, value: true }}
                                // validate={EmailValidateWithRequired}
                            >
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Email"
                                        type="email"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Email is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="mobile" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Mobile"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Mobile is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={6}>
                            <Field name="phone" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="Phone"
                                        type="text"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'Phone is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={12}>
                            <Field name="generalNotes" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <TextField
                                        {...input}
                                        label="General Notes"
                                        type="text"
                                        multiline={true}
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? 'General Notes is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                            <Field name="isMarketingConsent" type="checkbox" subscription={{ touched: true, error: true, value: true }}>
                                {({ input, meta }) => (
                                    <FormControlLabel
                                        className={classes.formControlLabel}
                                        control={<Checkbox {...input} checkedIcon={<CheckBoxIcon color="primary" />} />}
                                        label="Marketing Consent"
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                            <Field
                                name="birthDate"
                                validate={birthDateValidator}
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <KeyboardDatePicker
                                        {...input}
                                        value={input && input.value ? input.value : null}
                                        className={classes.textField}
                                        label="Birthdate (of Client)"
                                        format="DD/MM/YYYY"
                                        placeholder="dd/mm/yyyy"
                                        animateYearScrolling={true}
                                        // tslint:disable-next-line: max-line-length
                                        autoOk={true}
                                        allowKeyboardControl={false}
                                        variant={'inline'}
                                        error={meta.error && meta.touched}
                                        helperText={meta.error && meta.touched ? meta.error : ''}
                                        inputProps={{ autocomplete: 'off' }}
                                        InputLabelProps={{ shrink: true }}
                                        maxDate={new Date()}
                                        disabled={state.isPersonPanelDisabled}
                                        onChange={(date: MaterialUiPickersDate | null, value?: string | null) => input.onChange(date)}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <Grid item={true} xs={12} md={4} className={classes.customFieldGrid}>
                            <Field
                                // tslint:disable-next-line: max-line-length
                                name="gender"
                                subscription={{ touched: true, error: true, value: true }}
                            >
                                {({ input, meta }) => (
                                    <GenderSelector
                                        {...input}
                                        label="Gender (of Client)"
                                        name="gender"
                                        className={classes.textField}
                                        error={meta.error && meta.touched}
                                        // tslint:disable-next-line: max-line-length
                                        onSelection={(selection: IAutoCompleteItem, name: string) => input.onChange(selection.value)}
                                        helperText={meta.error && meta.touched ? 'Gender is required' : ''}
                                        disabled={state.isPersonPanelDisabled}
                                    />
                                )}
                            </Field>
                        </Grid>
                        <FormSpy subscription={{ values: true }}>
                            {/* tslint:disable-next-line: no-shadowed-variable */}
                            {({ values }) => {
                                if (!disabledPersonAddress(values)) {
                                    return (
                                        <>
                                            <Grid item={true} xs={12}>
                                                {addressPanel(true, form)}
                                            </Grid>
                                            <Grid item={true} xs={12}>
                                                {postalAddressPanel(false, form)}
                                            </Grid>
                                        </>
                                    );
                                }
                                return <div />;
                            }}
                        </FormSpy>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        );
    };

    // tslint:disable-next-line
    const addressPanel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion defaultExpanded={defaultExpanded}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                            <Typography className={classes.heading}>
                                <ContactMailIcon className={classes.headingIcon} />
                                Main Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {getAddressLabelForPanel(
                                            values.buildingName,
                                            values.unitLevel,
                                            values.number,
                                            values.street1,
                                            values.street2,
                                            values.citySuburb,
                                            values.state,
                                            values.postalCode,
                                            values.country,
                                        )}
                                        {/* {values.buildingName ? `${values.buildingName}, ` : ''}
                                        {values.unitLevel ? `${values.unitLevel}, ` : ''}
                                        {values.number ? `${values.number}, ` : ''}
                                        {values.street1 ? `${values.street1}, ` : ''}
                                        {values.citySuburb ? `${values.citySuburb}, ` : ''}
                                        {values.state ? `${values.state}, ` : ''}
                                        {values.postalCode ? `${values.postalCode}, ` : ''}
                                        {values.country ? `${values.country}` : ''} */}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.expandablePanelDetails}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} md={6} xs={12}>
                                    <Field
                                        name="buildingName"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Building Name"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Building Name is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Field
                                        name="unitLevel"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Unit/Level"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Unit/Level is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={3}>
                                    <Field
                                        name="number"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Number is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <FormSpy subscription={{ values: true }}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => (
                                            <Field name="street1" subscription={{ touched: true, error: true, value: true }}>
                                                {({ input, meta }) => (
                                                    <AddressSelector
                                                        {...input}
                                                        freeSolo={true}
                                                        label="Street 1"
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        name="street1"
                                                        homeCountry={props.homeCountry}
                                                        // tslint:disable-next-line: max-line-length
                                                        // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                        helperText={meta.error && meta.touched ? 'Street 1 is required' : ''}
                                                        onSelection={onSelection(form, values)}
                                                        onInputChanged={onInputChanged(form, 'street1')}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                    </FormSpy>
                                </Grid>
                                <Grid item={true} xs={12} md={6}>
                                    <FormSpy subscription={{ values: true }}>
                                        {/* tslint:disable-next-line: no-shadowed-variable */}
                                        {({ values }) => (
                                            <Field name="street2" subscription={{ touched: true, error: true, value: true }}>
                                                {({ input, meta }) => (
                                                    <AddressSelector
                                                        {...input}
                                                        freeSolo={true}
                                                        label="Street 2"
                                                        className={classes.textField}
                                                        error={meta.error && meta.touched}
                                                        name="street2"
                                                        homeCountry={props.homeCountry}
                                                        // tslint:disable-next-line: max-line-length
                                                        // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                        helperText={meta.error && meta.touched ? 'Street 2 is required' : ''}
                                                        onSelection={onSelection(form, values)}
                                                        onInputChanged={onInputChanged(form, 'street2')}
                                                    />
                                                )}
                                            </Field>
                                        )}
                                    </FormSpy>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="citySuburb" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="City/Suburb"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'City/Suburb is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="state" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'State is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="postalCode" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Postal Code is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Field name="country" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Country is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    // tslint:disable-next-line: max-line-length
    const postalAddressPanel = (defaultExpanded: boolean, form: FormApi<CreateClientForm>) => {
        return (
            <FormSpy subscription={{ values: true }}>
                {/* tslint:disable-next-line: no-shadowed-variable */}
                {({ values }) => (
                    <Accordion
                        // defaultExpanded={defaultExpanded}
                        expanded={state.isPersonPostalAddressExpandPanel}
                        onChange={onExpandPanel('isPersonPostalAddressExpandPanel')}
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" className={classes.AccordionSummary}>
                            <Typography className={classes.heading}>
                                <LocalPostOfficeIcon className={classes.headingIcon} />
                                Postal Address
                            </Typography>

                            <div className={classes.infoContainer}>
                                <div className="col">
                                    <div className="value">
                                        {values.postalPoBoxType ? `${values.postalPoBoxType} ` : ''}
                                        {values.postalPOBox ? `${values.postalPOBox}, ` : ''}
                                        {values.postalCitySuburb ? `${values.postalCitySuburb}, ` : ''}
                                        {values.postalState ? `${values.postalState}, ` : ''}
                                        {values.postalPostalCode ? `${values.postalPostalCode}, ` : ''}
                                        {values.postalCountry ? `${values.postalCountry}` : ''}
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className={classes.expandablePanelDetails}>
                            <Grid container={true} spacing={3}>
                                <Grid item={true} xs={12} md={4}>
                                    <Field
                                        name="postalPoBoxType"
                                        subscription={{ touched: true, error: true, value: true }}
                                        // validate={required}
                                    >
                                        {({ input, meta }) => (
                                            <POBoxTypeSelector
                                                {...input}
                                                label="PO Box Type"
                                                // required={true}
                                                value={input.value ? { label: input.value, value: input.value } : null}
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // tslint:disable-next-line: max-line-length
                                                onSelection={(selection: IAutoCompleteItem, name: string) => {
                                                    input.onChange(selection ? selection.value : null);
                                                }}
                                                helperText={meta.error && meta.touched ? 'Field is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={8}>
                                    <Field
                                        name="postalPOBox"
                                        subscription={{ touched: true, error: true, value: true }}
                                        validate={isPoBoxRequired(values, false) ? required : undefined}
                                    >
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Number"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                required={isPoBoxRequired(values, false) ? true : false}
                                                helperText={meta.error && meta.touched ? 'Number is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="postalCitySuburb" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <AddressSelector
                                                {...input}
                                                freeSolo={true}
                                                label="Suburb"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                homeCountry={props.homeCountry}
                                                // tslint:disable-next-line: max-line-length
                                                // placeTypes={['geocode', 'establishment']} // ['geocode', 'address', 'establishment', '(regions)', '(cities)'];
                                                helperText={meta.error && meta.touched ? 'City/Suburb is required' : ''}
                                                placeTypes={['(cities)']}
                                                onSelection={onSelection(form, values)}
                                                onInputChanged={onInputChanged(form, 'postalCitySuburb')}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="postalState" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="State"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'State is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12} md={4}>
                                    <Field name="postalPostalCode" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Postal Code"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                helperText={meta.error && meta.touched ? 'Postal Code is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                                <Grid item={true} xs={12}>
                                    <Field name="postalCountry" subscription={{ touched: true, error: true, value: true }}>
                                        {({ input, meta }) => (
                                            <TextField
                                                {...input}
                                                label="Country"
                                                type="text"
                                                className={classes.textField}
                                                error={meta.error && meta.touched}
                                                // disabled={props.sourceSystem?.toLowerCase() === 'leap'}
                                                helperText={meta.error && meta.touched ? 'Country is required' : ''}
                                            />
                                        )}
                                    </Field>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )}
            </FormSpy>
        );
    };

    const onCreateClientClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    return (
        <Drawer anchor={props.anchor} open={true} onClose={onCloseDrawer()} className={classes.root}>
            <Form
                onSubmit={(values, form: FormApi<CreateClientForm>) => onSubmit(form, values)}
                initialValues={initialValues}
                initialValuesEqual={() => true}
                keepDirtyOnReinitialize={true}
                subscription={{ submitting: true, pristine: true }}
                validate={(values) => {
                    let errors = {
                        secondPersonFirstName: '',
                        secondPersonLastName: ''
                    };

                    if (values.hasOwnProperty('secondPersonFirstName') && values.secondPersonFirstName && !values.secondPersonLastName) {
                        errors.secondPersonLastName = 'Required';
                    }

                    if (values.hasOwnProperty('secondPersonLastName') && values.secondPersonLastName && !values.secondPersonFirstName) {
                        errors.secondPersonFirstName = 'Required';
                    }

                    if (errors.secondPersonFirstName === '' && errors.secondPersonLastName === '') {
                        return undefined;
                    }

                    return errors;
                }}
                // debug={(values) => {
                //     // tslint:disable-next-line: no-console
                //     console.log('Debug Values', values);
                // }}
                render={({ handleSubmit, form, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit} id="createClient">
                        <div className={classes.formRoot}>
                            <h3>{`Create ${canCreateMatter ? 'Matter' : 'Client'}`}</h3>

                            <FormSpy subscription={{ pristine: true, values: true, errors: true }}>
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values, errors }) => {
                                    // check if has error and expand panel
                                    if (state.isFormSubmit) {
                                        openErrorPanel(errors);
                                    }
                                    // Buttons should be encapsulated in FormSpy - While close, we are refering to values and form
                                    return actionButton(form, submitting, pristine, values);
                                }}
                            </FormSpy>

                            <FormSpy
                                subscription={{ pristine: true, values: true }}
                                // tslint:disable-next-line: no-shadowed-variable
                                onChange={(props) => {
                                    onFormValueChanged(form, props);
                                }}
                            />

                            <DialogBox
                                title={createClientTitle}
                                // tslint:disable-next-line:max-line-length
                                content={`Are you sure you want to close the form?`}
                                show={state.showAlert && !state.isForceClosed}
                                isAgree={(agree: boolean) => onDiscardChanges(form, agree)}
                                disAgreeLabel={'No'}
                                agreeLabel={'Yes'}
                            />

                            <FormSpy subscription={{ pristine: true, values: true }}>
                                {/* Buttons should be encapsulated in FormSpy - While close, we are refering to values and form */}
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => (
                                    <>
                                        <DialogBox
                                            title={createClientTitle}
                                            // tslint:disable-next-line:max-line-length
                                            content={createClientMessage}
                                            show={state.showConfirmed}
                                            isAgree={(agree: boolean) => onConfirmed(form, values, agree)}
                                            disAgreeLabel={'No'}
                                            agreeLabel={'Yes'}
                                        />
                                        {canCreateMatter && createMatterExpandablePanel(form, submitting, pristine, values)}
                                        {organisationExpandablePanel(form, submitting, pristine, values)}
                                        {personExpandablePanel(true, form, submitting, pristine, values)}  
                                        {person2ExpandablePanel(true, form, submitting, pristine, values)}
                                    </>
                                )}
                            </FormSpy>

                            <FormSpy subscription={{ pristine: true, values: true }}>
                                {/* Buttons should be encapsulated in FormSpy - While close, we are refering to values and form */}
                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                {({ values }) => actionButton(form, submitting, pristine, values)}
                            </FormSpy>
                        </div>
                        {/* {printJson(values)} */}
                    </form>
                )}
            />
        </Drawer>
    );
};

// tslint:disable-next-line: no-any
// function printJson(values: any) {
//     if (values) {
//         return (
//             <>
//                 <pre>
//                     {JSON.stringify(values, undefined, 2)}
//                 </pre>
//             </>
//         );
//     } else {
//         return (
//             <>
//                 <FormSpy subscription={{ values: true }}>
//                     {/* tslint:disable-next-line: no-shadowed-variable */}
//                     {({ values }) => (
//                         <pre>
//                             {JSON.stringify(values, undefined, 2)}
//                             {/* <RenderCount /> */}
//                         </pre>
//                     )}
//                 </FormSpy>
//             </>
//         );
//     }
// }
